import React, { useEffect, useState } from 'react'
import "./suivis.css"
import star from "../../media/star.png"
import startred from "../../media/startred.png"
import thema from "../../media/ICONS THEMATIQUE/Fichier 5_1.png"
import amis from "../../media/ICONS THEMATIQUE/Fichier2.png"
import security from "../../media/ICONS THEMATIQUE/security.png"
import monde from "../../media/ICONS THEMATIQUE/monde.png"
import culture from "../../media/ICONS THEMATIQUE/Fichier 3.png"
import vivre from "../../media/ICONS THEMATIQUE/Fichier 4.png"
import z from "../../media/z.png"
import { countries } from "../../global/countriesAndSources"
import { deleteKeyword, fetchKeywordsFromUserId } from '../../HTTP/keywords'
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import Pagination from '../../Helper/Pagination'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const Suivis = () => {
    const user = JSON.parse(localStorage.getItem("user"))
    if (localStorage.getItem("subscriptions") === null) {
      window.location.href = '/'
    } else {
      if (JSON.parse(localStorage.getItem("subscriptions")).data === null) {
        window.location.href = '/'
      }
    }
    const url = new URL(window.location.href)
    if (url.searchParams.get('page') === null) {
        url.searchParams.set('page', 1)
        window.history.pushState({}, '', url)
    }
    const [keywords, setKeywords] = useState({count : 0, rows : []})
    const [keywordsReady, setKeywordsReady] = useState(false)
    const [itemsPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(parseInt(url.searchParams.get('page')))
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        setKeywordsReady(false)
        const funKeywords = async () => {
            const resKeywords = await fetchKeywordsFromUserId(user.id, (currentPage - 1) * itemsPerPage, currentPage * itemsPerPage, searchTerm)
            setKeywords(resKeywords)
        }
        funKeywords()
        setKeywordsReady(true)
    }, [currentPage])

    const handleKeywordSearch = (e) => {
        e.preventDefault()
        if (url.searchParams.get('query') === null) {
            url.searchParams.set('query', searchTerm)
        } else {
            url.searchParams.set('query', searchTerm)
        }
        window.history.pushState({}, '', url)
        setCurrentPage(1)
    }

    const handleSearch = (keyword) => {
      sessionStorage.setItem('search_country', JSON.stringify(countries.find((c) => c.code === keyword.country_code)))
      sessionStorage.setItem('search_query', keyword.keyword)
      window.location.href = `/${keyword.country_code}/what-this-country-talk-about?query=${keyword.keyword}${keyword.thematic ? `&thematic=${keyword.thematic}` : ''}`
    }

    const handleAdd = () => {
        window.location.href = '/addkeywords'
    }

    const handleDelete = async (keywordId) => {
      try {
        await deleteKeyword(keywordId)
        alert('Suppression successfull')
        window.location.reload()
      } catch (error) {
        alert('An error occured while deleting the keyword')
      }
    }

    return (
      <>
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between mt-10 mb:mt-0 mb-4">
          <h1 className="text-2xl font-bold text-center flex-grow">
            Mes mots-clés
          </h1>
        </div>
      
      <form onSubmit={handleKeywordSearch} className="w-full mb-4">
      <div className="flex flex-col space-y-3 lg:flex-row lg:space-y-0 lg:space-x-3">
        {/* Container principal qui prend toute la largeur disponible */}
        <div className="flex-1 flex space-x-2">
          <input
            type="text"
            placeholder="Rechercher..."
            className="flex-1 px-4 py-2.5 rounded-lg border border-gray-200 shadow-sm transition-colors focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-20 focus:outline-none"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            type="submit"
            className="px-4 py-2.5 bg-blue-500 hover:bg-blue-600 text-white rounded-lg shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <SearchIcon />
          </button>
          <button
            type="button"
            className="px-4 py-2.5 bg-green-500 hover:bg-green-600 text-white rounded-lg shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            onClick={handleAdd}
          >
            <AddIcon />
          </button>
        </div>
      </div>
    </form>
    <div className="md:block w-full shadow-md rounded-lg h-[calc(100vh-18rem)] overflow-y-auto">
      <table className="w-full table-auto">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mot-clé</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pays</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Thématique</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className='divide-y divide-gray-200'>
          {keywords.rows.map((keyword, i) => (
            <tr key={i} className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
              <td className="px-4 py-2">{keyword.keyword}</td>
              <td className="px-4 py-2">{countries.find((c) => c.code === keyword.country_code).name}</td>
              <td className="px-4 py-2">
                {keyword.thematic === null || keyword.thematic === "" ? 'Non défini' : keyword.thematic}
              </td>
              <td className="px-4 py-2">
              <button
                  onClick={() => handleSearch(keyword)}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold rounded mr-1 p-1"
                >
                  <SearchIcon />
                </button>
                <button
                  onClick={() => window.location.href=`/editkeywords/${keyword.id}`}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded mr-1 p-1"
                >
                  <EditIcon />
                </button>
                <button 
                    className="bg-red-500 hover:bg-red-700 text-white font-bold rounded mr-1 p-1"
                    onClick={() => {
                      if (window.confirm("Are you sure you want to delete this keyword?")) {
                        handleDelete(keyword.id);
                      }
                    }}
                  >
                    <DeleteIcon />
                  </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={keywords.count}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
    )


}

export default Suivis