import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./articles.css";
import Favo from "../../media/fav.jpeg"; 
import { removeFavorite, addArticlesToReview, getReviewById, getVisibleArticleFromUserId} from "../../HTTP/articles" 
import * as helpers from "../../Helper/utils"; // Importez tout le module helper
import userApi from "../../HTTP/user"
import { countries } from "../../global/countriesAndSources";

const ArticlesFav = () => {
  if (localStorage.getItem("subscriptions") === null) {
    window.location.href = '/'
  } else {
    if (JSON.parse(localStorage.getItem("subscriptions")).data === null) {
      window.location.href = '/'
    }
  }
  const user = JSON.parse(localStorage.getItem('user')) 
  const [error, setError] = useState("");
  const [favorites, setFavorites] = useState([]); 
  const [selection, setSelection] = useState([]); 
  const [searchTerm, setSearchTerm] = useState("");
  let { reviewid } = useParams(); // Assurez-vous que le nom du paramètre correspond à celui défini dans votre route 
	const [review, setReview] = useState([]); 
	  useEffect(() => {
      const fetchReview = async () => {
        const reviewData = await getReviewById(reviewid); // Assurez-vous que ceci renvoie un tableau
        setReview(reviewData);
        const favoritesnew = JSON.parse(localStorage.getItem("favorites")).filter(fav => 
          !reviewData.articles.some(article => article.link === fav.link)
        );
        const articlesData = await getVisibleArticleFromUserId(user.id)
        setFavorites([...favoritesnew, ...articlesData])
      };
      
		  fetchReview();
	  }, []); 
	   
  const [reviews, setReviews] = useState([]); 
	  useEffect(() => {
      const fetchReviews = async () => {
        try{
          const reviewsData = await userApi.getUserReviews(); // Assurez-vous que ceci renvoie un tableau
          console.log(reviewsData.reviews); // Debug: Qu'affiche cela ?
          setReviews(reviewsData.reviews);
        }
        catch{
          console.log('no reviews')
        }
      };
	  
		  fetchReviews();
	  }, []);
 

  // Fonction pour mettre à jour la sélection
  const updateSelection = () => {
    let favs = [];
    const checkedCheckboxes = document.querySelectorAll('.itemfav input[type="checkbox"]:checked');
    checkedCheckboxes.forEach(checkbox => {
      let parentItem = checkbox.closest('.itemfav');
      if (parentItem) {
        let dataId = parentItem.getAttribute('data-id');
        if (dataId) {
          favs.push(favorites[dataId]);
        }
      }
    });
    setSelection(favs);
    console.log(selection)
  };
 

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredFavorites = favorites.filter(fav => 
    fav.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    fav.link.toLowerCase().includes(searchTerm.toLowerCase()) ||
    fav.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    fav.country.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
 
  function removeFav(i) {
    const favorite = {
      link: helpers.htmlDecode(favorites[i].link)
    }
    removeFavorite(favorite).then((res) => {
        console.log(res) 
        document.getElementById("fav_"+i).remove();
        favorites.splice(i, 1); 
        localStorage.setItem('favorites', JSON.stringify(favorites)); 
    })
    alert('Favorite removed !');
  } 
  

  const addToReview = async () => { 
     selection.forEach(item => {
      delete item.publication_date;
      delete item.id;
      });
    const res = await addArticlesToReview(selection, reviewid)
			if(res === true) {
				alert('Articles added !')
        if (review.user_id === user.id) {
          window.location.href = `/editreviews/${review.id}`; 
        } else {
          window.location.href = `https://static.zemus.info/?id=${review.id}`
        }
				
			}
			else {
				alert('Error while adding articles')
			}
		}

  return (
      <>   
        <section className="articlesfav">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Rechercher parmi les favoris..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
            />
        </div>
            { 
              (() => {
                if (error.length > 0){
                  return <h1>{error}</h1>
                }
              })
              ()
            } 

            <div className="titleDivfav" style={{ display: "flex", marginTop: 55, marginBottom: 35}}>
                <button onClick={addToReview} className="mx-1">Ajouter à la revue de presse</button> 
            </div>

            {
              filteredFavorites.map((fav, i) => (
                    <div className="featuresfav" key={i} id={`fav_${i}`}>
                        <div className="itemfav rounded-lg shadow m-2" data-id={i}>
                          <div  className="item-photowrapper">
                              <img
                                  className="item-photofav"
                                  src={helpers.htmlDecode(fav.image || process.env.PUBLIC_URL + "/news_icon2.jpg") /**  IMAGE  */}
                                  alt="article"
                                  onClick={() => window.open(helpers.htmlDecode(fav.link), "_blank")} 
                                />
                          </div>
                          <div className="arttfav">
                            <div className="item-headerfav">
                              
                            </div>
                            <a href={helpers.htmlDecode(fav.link)} target="_blank" rel="noreferrer" style={{ textDecoration: "inherit", color: "inherit" }}>
                              <h3 className="item-titlefav">
                                  {helpers.htmlDecode(fav.title) }
                              </h3>
                              <p><strong className="text-cyan-600">
                                  Pays : {helpers.htmlDecode(fav.country === undefined ? countries.find((c) => c.code === user.country).name : fav.country) } | Source : {helpers.getDomainName(fav.link) } 
                                </strong></p>  
                              <p className="item-textfav">
                                  {helpers.removeHtmlTags(helpers.decodeHTML(fav.description)) }
                              </p>
                            </a>
                            {/* <a className="item-link" href="#">Click to see by yourself &rarr;</a> */}
                            <div>
                              <div  className="inline-block sm:mb-2">Présent dans : </div>
                            {Array.isArray(reviews) ? reviews.map(review => (
                                review.articles.filter(article => article.title === fav.title)
                                .map(filteredArticle => ( 
                                  <div key={filteredArticle.id} className="inline-block">
                                    <strong>{review.theme}</strong><span className="mx-1"> | </span>
                                  </div>
                                ))
                              )) : null} 
                            </div>

                          </div>

                          { true ? 
                          (
                            <div className="actiondiv">
                              <input type="checkbox" onChange={updateSelection}   />
                            </div>
                          ) : 
                          (
                            <div className="favicon"><img src={Favo} alt="fav icon"/></div>
                          )
                          }
                        </div>
                    </div>
              ))
            }

        </section>
      </>
  );
};

export default ArticlesFav;
