import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { fetchAllPriceSubscription, fetchPriceSubscriptionByCountry, fetchPrisSubscriptionByCountry } from '../../HTTP/admin';
import { getPublicKeyStripe, getSubscriptionClientSecret } from '../../HTTP/payment';


const CheckoutFormInner = ({ selectedPrice }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    
    if (!stripe || !elements || !selectedPrice) {
      setLoading(false);
      return;
    }
    
    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setErrorMessage(submitError.message);
        setLoading(false);
        return;
      }
      console.log(selectedPrice.id, user.id);
      
      const { client_secret } = await getSubscriptionClientSecret(selectedPrice.id, user.id);
      
      const url = new URL(window.location.href);
      
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret : client_secret,
        confirmParams: {
          return_url: `${url.origin}/nextpagepayment`,
        },
      });
      
      if (error) {
        setErrorMessage(error.message);
      }
    } catch (error) {
      setErrorMessage('Une erreur est survenue.');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <div className="mb-8 space-y-4">
        <div className="text-center">
          <h3 className="text-2xl font-bold text-gray-800">{selectedPrice?.title}</h3>
          <div className="mt-4 p-4 bg-gray-50 rounded-lg">
            <p className="text-3xl font-bold text-gray-900">
              {selectedPrice?.price} {selectedPrice?.currency}
            </p>
            <p className="text-sm text-gray-600 mt-2">
              Engagement {selectedPrice?.engaged_month} mois
            </p>
            {selectedPrice?.free_days > 0 && (
              <div className="mt-2 inline-block px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
                {selectedPrice?.free_days} jours d'essai gratuit
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mb-8">
        <PaymentElement />
      </div>

      <button 
        type="submit" 
        disabled={!stripe || !elements || loading}
        className="w-full py-3 px-4 bg-blue-600 hover:bg-blue-700 disabled:bg-gray-400 
        text-white font-semibold rounded-lg transition-colors duration-200"
        >
        {loading ? (
          <span className="flex items-center justify-center">
            <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z" />
            </svg>
            Traitement...
          </span>
        ) : (
          'Souscrire'
        )}
      </button>

      {errorMessage && (
        <div className="mt-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg">
          {errorMessage}
        </div>
      )}
    </form>
  );
};

export const CheckoutForm = () => {
  const [prices, setPrices] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [stripe, setStripe] = useState("");
  const [stripeReady, setStripeReady] = useState(false);
  
  useEffect(() => {
    const fetchPrices = async () => {
      const response = await fetchPriceSubscriptionByCountry(JSON.parse(localStorage.getItem('user')).country);
      setPrices(response);
      if (response.length > 0) {
        setSelectedPrice(response[0]);
      }
    };
    const fetchStripe = async () => {
      const response = await getPublicKeyStripe();
      setStripe(response.value);
    }
    fetchStripe();
    fetchPrices();
    setStripeReady(true);
  }, []);

  console.log(stripe)

  const stripePromise = stripe ? loadStripe(stripe) : null;
  
  const options = {
    mode: 'subscription',
    currency: selectedPrice?.currency || 'eur',
    payment_method_types: ['card'],
    amount: selectedPrice?.price * 100,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#3B82F6',
      },
    },
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-center text-3xl font-bold text-gray-900 mb-8">
          Choisissez votre abonnement
        </h2>
        
        <div className="flex flex-wrap justify-center gap-4 mb-12">
          {prices.map(price => (
            <button
              key={price.id}
              onClick={() => setSelectedPrice(price)}
              className={`p-4 rounded-lg border-2 transition-all duration-200 hover:shadow-md
                ${selectedPrice?.id === price.id 
                  ? 'border-blue-600 bg-blue-50' 
                  : 'border-gray-200 hover:border-blue-300'}`}
              style={{ flex: '1 1 200px', maxWidth: '300px' }}
            >
              <h3 className="font-bold text-lg mb-2">{price.title}</h3>
              <p className="text-2xl font-bold text-gray-900">
                {price.price} {price.currency}
              </p>
            </button>
          ))}
        </div>

        {selectedPrice && stripeReady && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutFormInner selectedPrice={selectedPrice} />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default CheckoutForm;