import authHeader from "./auth.header"
import constants from './constants.js'

export async function getClientSecret() { 
    const response = await fetch(`${constants.API_URL}/payment/client_secret`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.error('Request failed:', {
            status: response.status,
            statusText: response.statusText,
            body: await response.json()
        });
        throw new Error('Failed to fetch client secret');
    }

    return await response.json();
}

export async function getSubscriptionClientSecret(selectedPrice, userId) {
    const response = await fetch(`${constants.API_URL}/payment/subscriptions`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({
            subscriptionPriceId: selectedPrice,
            userId: userId
        })
    });

    if (!response.ok) {
        console.error('Request failed:', {
            status: response.status,
            statusText: response.statusText,
            body: await response.json()
        });
        throw new Error('Failed to create subscription client secret');
    }

    const data = await response.json();
    return data;
}

export async function getPublicKeyStripe() {
    const response = await fetch(`${constants.API_URL}/options/public_key_stripe`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
        },
    });

    if (!response.ok) {
        console.error('Request failed:', {
            status: response.status,
            statusText: response.statusText,
            body: await response.json()
        });
        throw new Error('Failed to fetch public key');
    }

    const data = await response.json();
    return data;
}