import constants from './constants.js'

const HOST_URL = constants.API_SCHEME + "://" + constants.API_HOST_NAME + ":" + constants.API_PORT;

export async function fetchThematicsFromCountry(countryCode) {

    const response = await fetch(`${HOST_URL}/thematics/${countryCode}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    return await response.json()
}

export async function fetchAllThematics() {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await fetch(`${HOST_URL}/thematics/name/${user.id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    return await response.json()
}

export async function fetchRawThematics() {
    const response = await fetch(`${HOST_URL}/thematics/raw`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    return await response.json()
}

export async function fetchCseFromThematic(name, countryCode) {
    const response = await fetch(`${HOST_URL}/thematics/cse/${name}/${countryCode}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.text())
        return []
    }

    return await response.text()
}

export async function fetchCountryInfo(country) {
    const response = await fetch(`${HOST_URL}/thematics/country/infos/${country}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    return await response.json()
}

export async function toggleUserThematic(user_id, thematic_id) {
    const response = await fetch(`${HOST_URL}/thematics/user/toggle`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({ user_id: user_id, thematic_id: thematic_id })
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return false
    }

    return true
}

const thematics = {
    fetchThematicsFromCountry
}
export default thematics;
