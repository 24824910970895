import React, { useState, useEffect } from "react"
import "./Admin.css"
import { fetchAllOptions, updateOption } from "../../HTTP/admin"
import SideBarAdmin from "./SideBarAdmin"

export default function OptionsAdmin() {

    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.super_admin) {
        window.location.href = '/'
    }
    
    const [options, setOptions] = useState([]);
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                setIsLoading(true);
                const resFetchOptions = await fetchAllOptions();
                setOptions(resFetchOptions);
                
                // Initialize formData with default values
                const initialFormData = resFetchOptions.reduce((acc, option) => ({
                    ...acc,
                    [option.key]: option.value || '' // Ensure empty string as fallback
                }), {});
                
                setFormData(initialFormData);
            } catch (error) {
                console.error("Error fetching options:", error);
            } finally {
                setIsLoading(false);
            }
        };
        
        fetchOptions();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await updateOption(formData);
            console.log(res);
            window.location.reload();
        } catch (error) {
            console.error("Error updating options:", error);
        }
    };

    if (isLoading) {
        return (
            <div className="flex">
                <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
                    <p className="text-center">Loading...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="flex mt-8">
            <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-6 text-center">Options</h2>
                <form onSubmit={handleSubmit}>
                    {options.map((option, index) => (
                        <div className="mb-4" key={option.key || index}>
                            <label htmlFor={option.key} className="block text-gray-700 text-sm font-bold mb-2">
                                {option.label}
                            </label>
                            <input
                                type="text"
                                id={option.key}
                                name={option.key}
                                value={formData[option.key] || ''}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    ))}
                    <div className="flex items-center justify-end">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}