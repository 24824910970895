import { useEffect } from "react"
import { getAllPayments } from "../../HTTP/admin";
import { useState } from "react";
import SideBarAdmin from "./SideBarAdmin";
import TableElements from "./TableElements";
import ListElementsMobile from "./ListElementsMobile";

export default function PaymentsAdmin() {

    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.super_admin) {
        window.location.href = '/'
    }

    const [payments, setPayments] = useState([])
    useEffect(() => {
        const fetchPayments = async () => {
            const paymentsData = await getAllPayments();
            setPayments(paymentsData);
        };
        fetchPayments();
    }, []);

    return (
        <div className="flex mt-8">
            <div className="flex-grow px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col sm:flex-row items-center justify-normal sm:justify-between mb-6 mt-8 xl:mt-0">
                    <h1 className="text-2xl font-bold text-center flex-grow mt-2">
                        Paiements
                    </h1>
                </div>
                {/* <SearchComponent
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    handleQuery={handleQuery}
                    dropdownList={localUser.super_admin === 1 ? {select:'Pays', value:country, handle:handleChangeCountry} : {}}
                    otherButton={{title:'Ajouter', handle:handleAddUser}}
                /> */}
                <div className='hidden xl:block'>
                    <TableElements 
                        columns={[{props:['user_firstname','user_lastname'],display:"Utilisateur"}, 
                        {props:'amount',display:"Montant"}, 
                        {props:'currency',display:"Devise"},
                        {props:'created_at',display:"Date de paiement", format:'date'},
                        ]}
                        data={payments}
                    />
                </div>
                <div className="xl:hidden w-full px-4 space-y-4">
                    <ListElementsMobile 
                        columns={[{props:['user_firstname','user_lastname'],display:"Utilisateur"}, 
                        {props:'amount',display:"Montant"}, 
                        {props:'currency',display:"Devise"},
                        {props:'created_at',display:"Date de paiement", format:'date'},
                        ]}
                        data={payments}
                    />
                </div>
            </div>
        </div>
    )
}