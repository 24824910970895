import React, { useState, useEffect } from "react"
import "./Admin.css"
import { fetchAllOptions, getAdminCountNotifications, updateOption } from "../../HTTP/admin"
import SideBarAdmin from "./SideBarAdmin"

export default function Admin() {

    return (
        <>
        <div className="flex mt-8">
            <div className="welcome-container flex-grow">
                <h1 className="text-center welcome-title mt-4 text-2xl font-bold">
                    Bienvenue dans la page d'administration
                </h1>
                <p className="text-center welcome-message">
                    Vous pouvez gérer les utilisateurs, les abonnements, les articles, les options et les notifications ici.
                </p>
            </div>
        </div>
        </> 
    )
}