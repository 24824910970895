import React from 'react'
import "./Subscription.css"
// import zlogo from "../../media/z.png"
import Log from "../../media/videoFont.png"
import Gratuit from "../../media/normal.png"
import ROYAL from "../../media/prem.png"
import { Link } from 'react-router-dom'
import { Rocket } from '@mui/icons-material'

const Payment = () => {
    // We'll use a placeholder image URL since we can't access the original images
    const backgroundImage = "/api/placeholder/1920/1080";
  
    return (
      <div className="min-h-screen bg-cover bg-no-repeat bg-center px-4 py-8" style={{ backgroundImage: `url(${Log})` }}>
        {/* Header */}
        <div className="max-w-7xl mx-auto flex items-center justify-between mb-8">
          <h2 className="text-2xl md:text-3xl text-white font-medium">
            Profitez de la version Premium avec des accès exclusifs pour votre voyage sur Zemus:
          </h2>
          <Link to={"/"}><span className="material-symbols-outlined">
home
</span></Link>
        </div>
  
        {/* Cards Container */}
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row gap-8 justify-center items-stretch mb-12">
          {/* Free Version Card */}
          <div className="bg-white rounded-lg shadow-lg w-full md:w-96">
            <div className="bg-[#009AAD] py-4 rounded-t-lg">
              <h3 className="text-xl text-white font-medium">Version de base gratuite</h3>
            </div>
            <div className="p-6">
              <div className="flex items-center justify-around mb-6">
                <img src={Gratuit} alt="Version gratuite" className="w-32" />
                <div className="flex flex-col">
                  <p className="text-xl font-medium">Version <span className="text-[#009AAD]">Gratuite</span></p>
                  <p className="text-xl font-medium">0.00 <span className="text-[#009AAD]">€</span> / au mois</p>
                </div>
              </div>
              <ul className="text-left space-y-2">
                <li className="before:content-['•'] before:text-[#7da3e0] before:mr-2">Recherche dans chaque pays</li>
                <li className="before:content-['•'] before:text-[#7da3e0] before:mr-2">Recherche dans sa langue (mots-clefs) d'informations en langues étrangères</li>
                <li className="before:content-['•'] before:text-[#7da3e0] before:mr-2">Rechercher et consulter les profils d'autres membres de Zemus</li>
              </ul>
            </div>
          </div>
  
          {/* Premium Version Card */}
          <div className="bg-white rounded-lg shadow-lg w-full md:w-[640px]">
            <div className="bg-[#FFE500] py-4 rounded-t-lg">
              <h3 className="text-xl text-white font-medium">Obtenez plus avec Premium</h3>
            </div>
            <div className="p-6">
              <div className="flex items-center justify-around mb-6">
                <img src={ROYAL} alt="Version premium" className="w-40" />
                <div className="flex flex-col">
                  <p className="text-xl font-medium">Version <span className="text-[#FFE500]">Premium</span></p>
                  <p className="text-xl font-medium">9.00 <span className="text-[#FFE500]">€</span> / au mois</p>
                  <p className="text-xl font-medium">7.00 <span className="text-[#FFE500]">€</span> etudiant et demandeur</p>
                </div>
              </div>
              <ul className="text-left space-y-2 font-medium">
                {[
                  'Recherche dans chaque pays',
                  'Recherche dans sa langue (mots-clefs) d\'informations en langues étrangères',
                  'Suivi et partage des articles de presse',
                  'Archivage d\'articles favoris',
                  'Suivi thématique de la presse par pays',
                  'Réalisation et publication de revues de presse',
                  'Rechercher et consulter les profils d\'autres membres de Zemus',
                  'Suivre les pays et les thématiques de votre choix'
                ].map((feature, index) => (
                  <li key={index} className="before:content-['•'] before:text-[#7da3e0] before:mr-2">{feature}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
  
        {/* Footer */}
        <div className="text-center">
          <button 
            onClick={() => window.location.href = "/addsubscription"} 
            className="bg-[#FFE500] text-black font-medium px-8 py-3 rounded-full text-lg hover:bg-[#FFE500]/90 transition-colors mb-4"
          >
            Passer a la version Premium
          </button>
          <h2 className="text-xl text-white">
            Les membres Premium peuvent profiter de nombreux avantages liés à Zemus!
          </h2>
        </div>
      </div>
    );
  };

export default Payment