import { getNestedValue, tailwindClassForColoredButton } from "../../Helper/utils"

export default function LineForTable({columns, element, index, buttons}) {
    // Fonction pour vérifier si le bouton doit être affiché
    const shouldShowButton = (button) => {
        // Si la condition n'est pas définie, on affiche toujours le bouton
        if (button.condition === undefined || button.condition === null) {
            return true;
        }
        
        // Si la condition est une fonction, on l'évalue avec l'élément
        if (typeof button.condition === 'function') {
            return button.condition(element);
        }
        
        // Sinon, on retourne la valeur directe de la condition
        return button.condition;
    };

    return (
        <tr className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
            {columns.map((column, colIndex) => {
                if (column.display === "Actions") {
                    return (
                        <td key={`${index}-${colIndex}`} className="px-4 py-4 break-words">
                            <div className="flex flex-col space-y-2">
                                {buttons.map((b, buttonIndex) => (
                                    shouldShowButton(b) && (
                                        <button
                                            key={`${index}-${colIndex}-${buttonIndex}`}
                                            className={tailwindClassForColoredButton(b.color)}
                                            onClick={() => b.action(getNestedValue(element, b))}
                                        >
                                            {b.display}
                                        </button>
                                    )
                                ))}
                            </div>
                        </td>
                    );
                }
                
                return (
                    <td key={`${index}-${colIndex}`} className="px-4 py-4 break-words">
                        <div className="max-w-xs lg:max-w-sm xl:max-w-md">
                            {getNestedValue(element, column)}
                        </div>
                    </td>
                );
            })}
        </tr>
    );
}