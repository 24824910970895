import React, { useState } from 'react';
import Side from '../sidecontainer/side';
import SideBarAdmin from './SideBarAdmin';

export const SettingsSubscriptionAdmin = () => {
    const [formData, setFormData] = useState({
        publicKey: '',
        secretKey: '',
        webhookSecret: '',
        planId: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log('Form data submitted:', formData);
    };

    return (
        <div className="flex mt-8">
            <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-6 text-center">Stripe Configuration</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="publicKey" className="block text-gray-700 text-sm font-bold mb-2">
                            Public Key:
                        </label>
                        <input
                            type="text"
                            id="publicKey"
                            name="publicKey"
                            value={formData.publicKey}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="secretKey" className="block text-gray-700 text-sm font-bold mb-2">
                            Secret Key:
                        </label>
                        <input
                            type="text"
                            id="secretKey"
                            name="secretKey"
                            value={formData.secretKey}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="webhookSecret" className="block text-gray-700 text-sm font-bold mb-2">
                            Webhook Secret:
                        </label>
                        <input
                            type="text"
                            id="webhookSecret"
                            name="webhookSecret"
                            value={formData.webhookSecret}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="planId" className="block text-gray-700 text-sm font-bold mb-2">
                            Plan ID:
                        </label>
                        <input
                            type="text"
                            id="planId"
                            name="planId"
                            value={formData.planId}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            required
                        />
                    </div>
                    <div className="flex items-center justify-end">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Save Settings
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SettingsSubscriptionAdmin;