import { useEffect, useState } from "react";
import { deleteNotification, getNotifications, updateSeenNotification } from "../../HTTP/user";
import { formatDate, formatDateExtended } from "../../Helper/utils";
import { getStatusVisibleArticle } from "../../HTTP/articles";

export default function Notifications() {

  const [notifications, setNotifications] = useState([]);

  // Premier useEffect pour charger les notifications
  useEffect(() => {
    const loadNotifications = async () => {
      const resNotifs = await getNotifications();
      setNotifications(resNotifs);
    };
    loadNotifications();
  }, []);
  
  // Deuxième useEffect pour gérer la visibilité et le statut "vu"
  useEffect(() => {
    const updateNotificationsStatus = async () => {
      // On attend que toutes les promesses soient résolues
      const updatedNotifications = await Promise.all(
        notifications.map(async (notification) => {
          let visible_article = 0;
          
          // Vérification de la visibilité de l'article
          if (notification.article_id !== null) {
            const res = await getStatusVisibleArticle(notification.article_id);
            visible_article = res ? res.visible : 0;
          }
  
          // Mise à jour du statut "vu"
          if (notification.seen === 0) {
            try {
              await updateSeenNotification(notification.id);
              console.log("notification vue");
            } catch (error) {
              console.error("erreur pour mettre en vue les notifications", error);
            }
          }
  
          // On retourne un nouvel objet avec toutes les propriétés
          return {
            ...notification,
            visible_article,
            seen: 1 // On met à jour le statut seen directement
          };
        })
      );
  
      // Mise à jour de l'état avec le nouveau tableau
      setNotifications(updatedNotifications);
    };
  
    updateNotificationsStatus();
  }, [notifications.length]); // On ne déclenche que quand le nombre de notifications change

  console.log(notifications)
    
    const handleArticle = (articleId) => {
      window.location.href = "/admin/articles/check/" + articleId;
    }

    return (
        <>
        <div className="w-full px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between mt-10 mb-4">
        <h1 className="text-2xl font-bold text-center flex-grow">
          Mes notifications
        </h1>
      </div>

      <div className="w-full overflow-x-auto shadow-md rounded-lg">
        <table className="w-full table-auto">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Message</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {notifications.map((item, index) => (
              <tr key={item.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-4 py-4 break-words">
                  <div className={item.seen === 0 ? " font-bold" : "font-normal" + "max-w-xs lg:max-w-sm xl:max-w-md"}>{item.message}</div>
                </td>
                <td className="px-4 py-4 break-words">
                  <div className="max-w-xs lg:max-w-sm xl:max-w-md">{formatDateExtended(item.date)}</div>
                </td>
                <td className="px-4 py-4 whitespace-nowrap">
                  {
                    item.to_admin === 1 && item.visible_article === 1 && <button 
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                    onClick={() => {handleArticle(item.article_id)
                    }}
                  >
                    Modérer
                  </button>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
        </>
    )
}