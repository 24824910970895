import React, { useState, useEffect } from 'react'
import "../Component/Home/Home.css"
import Side from "../Component/sidecontainer/side";
import homePage from "../media/accpage.svg";
import amis from "../media/amis.svg";
import conversation_apropo from "../media/conversation_apropo.svg";
import fils from "../media/fils.svg";
import audioCallFont from "../media/audioCallFont.jpeg";
import videocall from "../media/videoFont.png"
import cover from "../media/font.jpeg";
import logonobg from "../media/logonobg.png";
import fondfont from "../media/font.jpeg";
import { Bars3Icon } from '@heroicons/react/24/outline';
import Translate from '../Component/Translate/Translate';
import SideBarAdmin from '../Component/Admin/SideBarAdmin';

const locationInfo = {
    "/": {
        bgImage: homePage,
        sideBar: "thematics"
    },
    "search": {
        bgImage: fils,
        sideBar: "thematics"
    },
    "friends-msg": {
        bgImage: cover,
        sideBar: "empty"
    },
    "profile": {
        bgImage: cover,
        sideBar: "empty"
    },
    "friends": {
        bgImage: amis,
        sideBar: "empty"
    },
    "favourites": {
        bgImage: amis,
        sideBar: "empty"
    },
    "conv": {
        bgImage: conversation_apropo,
        sideBar: "friends_requests"
    },
    "feed": {
        bgImage: cover,
        sideBar: "empty"
    },
    "audiocall": {
        bgImage: audioCallFont,
        sideBar: "empty"
    },    
    "videocall": {
        bgImage: videocall,
        sideBar: "empty"
    },    
    "reviews": {
        bgImage: cover,
        sideBar: "empty"
    },    
    "myarticles": {
        bgImage: cover,
        sideBar: "empty"
    }
} 

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(window.innerWidth >= 768);
    let currentPath = (window.location.href.split("/")[3]).split("?")[0]
    if (!locationInfo[currentPath]) {
        currentPath = "/"
    }
    useEffect(() => {
        const handleResize = () => {
            setIsOpen(window.innerWidth >= 768);
        };
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div className="mainsidebar">
            {/* Bouton pour basculer l'état de la sidebar */}
            <button onClick={() => setIsOpen(!isOpen)} className="menuburger">
                <Bars3Icon className="h-6 w-6" />
            </button>

            {/* Contenu de la Sidebar */}
            <div className={`z-20 inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full w-0 overflow-hidden'} transition duration-200 ease-in-out`}>
            <Side sideBarLowerSection = { locationInfo[currentPath].sideBar }/>
            </div>
        </div>
    );
};

export default function GeneralContainerWrapper(Component) {

    let currentPath = (window.location.href.split("/")[3])
    if (!locationInfo[currentPath]) {
        currentPath = "/"
    }

    if (window.location.href.split("/")[3] == "reviews" || !localStorage.getItem("token")) {
        return (props) => {
            return (
                <div >  
                        <div className="public-banner flex w-full">
                            <div className="public-grey p-4">
                                <img src={logonobg} width="150" alt="Logo" />  
                            </div> 
                             <div className="public-blue p-2 w-full">
                                <a href="/login" className="text-white bg-transparent border border-white mr-2  rounded-xl">
                                    Je me connecte
                                </a>
                                <a href="login" className="bg-white rounded-xl">
                                    Je m'inscris
                                </a>
                            </div>
                        </div>        
                        <div className="public-container"
                         style = {{ backgroundImage: `url(${ fondfont })`,
                                    backgroundAttachment: 'fixed',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'}}   
                         >
                            <Component/>
                        </div>  
                </div>
            )
         }
    }

    return (props) => {
        return (
            <div
                className="homecontainer"
                style = {{ backgroundImage: `url(${ locationInfo[currentPath].bgImage })` }}   
            >
                <div className="sidprof">
                    <div className="flex">
                        <Sidebar/>
                                <div className="allcontainer">
                                    <Translate/>
                                    <Component/>
                                </div>
                    </div>
                </div>
            </div>
        )
    }
}

export function AdminContainerWrapper(Component) {
    return (props) => {
        const localUser = JSON.parse(localStorage.getItem("user"))
        if (!localUser.admin) {
            window.location.href = '/'
        }
        return (
            <div className="homecontainer">
                <div className="sidprof">
                    <div className="flex">
                        <SideBarAdmin />
                        <div className="allcontainer">
                            <Translate/>
                            <Component/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


