import authHeader from "./auth.header.js"
import constants from './constants.js'

const HOST_URL = constants.API_SCHEME + "://" + constants.API_HOST_NAME + ":" + constants.API_PORT;

export async function fetchSubscriptionFromUserId(userId) {
    try {
        const response = await fetch(`${HOST_URL}/subscription/user/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });

        if (response.status === 404) {
            return null;
        }

        if (!response.ok) {
            console.error('Request failed:', {
                status: response.status,
                statusText: response.statusText,
                body: await response.json()
            });
            throw new Error('Failed to fetch subscription');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching subscription:', error);
        throw error;
    }
}

export async function cancelSubscription(userId) {
    try {
        const response = await fetch(`${HOST_URL}/payment/subscriptions/cancel/${userId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
        });

        if (!response.ok) {
            console.error('Request failed:', {
                status: response.status,
                statusText: response.statusText,
                body: await response.json()
            });
            throw new Error('Failed to cancel subscription');
        }

        return true;
    } catch (error) {
        console.error('Error cancelling subscription:', error);
        throw error;
    }
}