import React, { useEffect, useState } from 'react';
import { getArticles, removeOwnArticle } from '../../HTTP/articles';
import { formatDate, htmlDecode, removeHtmlTags } from '../../Helper/utils';
import { getUserById, updateVisibleArticle } from '../../HTTP/admin';
import { ArrowLeft } from "@mui/icons-material";
import { languages } from '../../global/countriesAndSources';
import { getAllLanguagesFromCountry } from '../../HTTP/translation';
import TableElements from './TableElements';
import ListElementsMobile from './ListElementsMobile';
import SearchComponent from './SearchComponent';
import Pagination from '../../Helper/Pagination';
import SideBarAdmin from './SideBarAdmin';
import Translate from '../Translate/Translate';

export default function ArticlesAdmin() {
    const user = JSON.parse(localStorage.getItem("user"))
    if (!user.admin && !user.super_admin) {
        window.location.href = "/"
    }

    const url = new URL(window.location.href)
      if (url.searchParams.get('page') === null) {
          url.searchParams.set('page', 1);
          window.history.pushState({}, '', url);
      }
      if (url.searchParams.get('language') !== null) {
        if (url.searchParams.get('language') === "al" && !user.super_admin) {
          url.searchParams.delete('language')
        }
      }
    
    const [languagesOfUser, setLanguagesOfUser] = useState([])
    const [listLang, setListLang] = useState([])
    const [selectedLanguage, setSelectedLanguage] = useState(url.searchParams.get('language') !== null ? url.searchParams.get('language') : "")
    const [articles, setArticles] = useState({count:0, rows:[]});
    const [articlesWithAuthor, setArticlesWithAuthor] = useState([])
    const [searchTerm, setSearchTerm] = useState(url.searchParams.get('query') === null ? '' : url.searchParams.get('query'));
    const [currentPage, setCurrentPage] = useState(parseInt(url.searchParams.get('page')));
    const [itemsPerPage] = useState(5);

    useEffect(() => {
      const initializeData = async () => {

        // 1. Récupérer les langues de l'utilisateur
        const resLang = await getAllLanguagesFromCountry(user.country)

        // 2. Mettre en forme les langues de l'utilisateur
        setListLang(resLang.map((item) => {
          return languages.find((lang) => lang.language_code === item)
        }))

        // 4. Récupérer les articles avec la bonne langue
        const resArticles = await getArticles(
          (currentPage - 1) * itemsPerPage, 
          currentPage * itemsPerPage, 
          searchTerm, 
          1, 
          user.super_admin === 1 ? 'al' : resLang.join(',')
        )
        setArticles(resArticles)
    
        const upList = await Promise.all(
          resArticles.rows.map(async (item) => {
            const secondResponse = await getUserById(item.user_id)
            return {article: item, user: secondResponse}
          })
        )
        setArticlesWithAuthor(upList)
      }
    
      initializeData()
  }, [])

  const handleSearch = (e) => {
    e.preventDefault();
    if (url.searchParams.get('query') === null) {
        url.searchParams.set('query', searchTerm);
        window.history.pushState({}, '', url);
    }
    window.location.href = "/admin/articles?page=1&query=" + searchTerm
  };

  const handleCheck = async (id) => {
    const res = await updateVisibleArticle(id, 2, "")
    window.location.href = "/admin/articles/check/" + id
  };

  const handleDelete = (item) => {
        removeOwnArticle(item).then(async () => {
          alert("Article supprimée !")
        }).catch((err) => alert("Erreur lors de la suppression de la revue !"))
        window.location.href = "/admin/articles"
  };

  const handleBackToDashboard = () => {
    window.location.href = "/admin";
  };

  const handleChangeLanguage = (e) => {
    window.location.href = "/admin/articles?page=1&language=" + e.target.value
  }

  // Changer de page
  const paginate = (pageNumber) => window.location.href = "/admin/articles?page=" + pageNumber + (url.searchParams.get("query") === null ? '' : "&query=" + url.searchParams.get("query")) + (url.searchParams.get("language") === null ? '' : "&language=" + url.searchParams.get("language"));

  return (
    <>
    <div className="flex flex-row mt-8">
    <div className="w-full px-4 sm:px-6 lg:px-8 ">
      <div className="flex flex-col sm:flex-row items-center justify-normal sm:justify-between mb-6 mt-8 xl:mt-0">
        <h1 className="text-2xl font-bold text-center flex-grow">
          Modération des articles à mettre en ligne
        </h1>
      </div>
      <SearchComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleQuery={handleSearch}
        />
      <div className='hidden xl:block'>
      <TableElements 
        columns={[{props:'article.title',display:"Titre"}, 
          {props:['user.firstname','user.lastname'],display:"Auteur",format:'fullName'}, 
          {props:'article.language',display:"Langue", format:'language'}, 
          {props:'article.created_at',display:"Date de création", format:'date'}, 
          {props:'article.updated_at',display:"Date d'actualisation", format:'date'}, 
          {props:'',display:"Actions"}
        ]}
        data={articlesWithAuthor}
        buttons={[{display:'Modérer l\'article',action:handleCheck, color:'blue', props:'article.id'}, ]}
      />
      </div>
      <div className="xl:hidden w-full px-4 space-y-4">
      <ListElementsMobile 
              columns={[{props:'article.title',display:"Titre"}, 
                {props:['user.firstname','user.lastname'],display:"Auteur",format:'fullName'}, 
                {props:'article.language',display:"Langue", format:'language'}, 
                {props:'article.created_at',display:"Date de création", format:'date'}, 
                {props:'article.updated_at',display:"Date d'actualisation", format:'date'}, 
                {props:'',display:"Actions"}
              ]}
              data={articlesWithAuthor}
              buttons={[{display:'Modérer l\'article',action:handleCheck, color:'blue', props:'article.id'}]}
              little={true}
            />
      {/* <div className="h-[calc(100vh-12rem)] overflow-y-auto">
        {articlesWithAuthor.map((item, index) => (
          <div key={item.article.id} className="bg-white p-4 rounded-lg shadow-md mb-2">
            <div className="space-y-3">
                <div className="font-medium">{item.article.title}</div>
                <div className="text-sm text-gray-600">{item.user.firstname + " " + item.user.lastname}</div>

              <div className="text-sm">
                <span className="text-gray-500">Description : </span>
                {removeHtmlTags(item.article.description).length > 150 
                  ? removeHtmlTags(item.article.description).slice(0, 50) + " ..." 
                  : removeHtmlTags(item.article.description)}
              </div>

              <div className="text-sm grid grid-cols-2 gap-2">
                <div>
                  <span className="text-gray-500">Créé : </span>
                  {formatDate(item.article.created_at)}
                </div>
                <div>
                  <span className="text-gray-500">Mis à jour : </span>
                  {formatDate(item.article.updated_at)}
                </div>
              </div>

              <div className="flex flex-col space-y-2 pt-2">
                <button 
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full"
                  onClick={() => handleViewArticle(item.article.id)}
                >
                  Voir l'article
                </button>
                
                <button 
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                  onClick={() => handleEdit(item.article.id)}
                >
                  Éditer
                </button>
                
                {item.article.visible === 1 ? (
                  <button 
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded w-full"
                    onClick={() => handleVisibleArticle(item.article)}
                  >
                    Masquer l'article
                  </button>
                ) : (
                  <button 
                    className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded w-full"
                    onClick={() => handleVisibleArticle(item.article)}
                  >
                    Publier
                  </button>
                )}
                
                <button 
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full"
                  onClick={() => {
                    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet élément ?")) {
                      const reason = prompt("Donner la raison de la suppression de cet article")
                      handleDelete({...item.article, message:reason});
                    }
                  }}
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        ))}
        </div> */}
      </div>
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={articles.count}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
    </div>
    </>
  );
}