export default function HeaderTable({columns}) {
    return (
        <>
        <thead className="bg-gray-100 sticky top-0 z-4">
        <tr>
          {
            columns.map((element, index) => (
            <th key={index} className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{ element.display }</th>)
          )}
        </tr>
      </thead>
        </>
    )
}