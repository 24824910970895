import authHeader from "./auth.header.js"
import constants from './constants.js'

const HOST_URL = constants.API_SCHEME + "://" + constants.API_HOST_NAME + ":" + constants.API_PORT;

async function getTranslation(langStart, langDest, strStart){

    const response = await fetch(`${HOST_URL}/translate/${langStart}/${langDest}/${strStart}`, {
        method: 'GET',
        headers: {
            'Accept': 'text',
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return ""
    }

    return await response.text()
}

export async function getLangFromCountry(countryCode){

    const response = await fetch(`${HOST_URL}/translate/lang/${countryCode}`, {
        method: 'GET',
        headers: {
            'Accept': 'text',
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return ""
    }

    return await response.text()
}

export async function getAllLanguagesFromCountry(countryCode){

    const response = await fetch(`${HOST_URL}/translate/languages/${countryCode}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    return await response.json()
} 

export async function getIgo() {
    const response = await fetch(`${HOST_URL}/countries/igo`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    return await response.json()
}

export async function getAllCountries(){

    const response = await fetch(`${HOST_URL}/countries`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    return await response.json()
}

export async function getCountriesFromCountryList(countryCodes){

    let response;
    if (countryCodes === ''){
        response = await fetch(`${HOST_URL}/countries`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        })
    } else {
        response = await fetch(`${HOST_URL}/countries/${countryCodes}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        })
    }

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    return await response.json()
}

const translation = {
    getTranslation,
    getLangFromCountry,
    getAllLanguagesFromCountry
}
export default translation;
