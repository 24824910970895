import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { addKeyword, createKeyword } from "../../HTTP/keywords";
import { fetchAllThematics, fetchRawThematics } from "../../HTTP/thematics";
import { countries } from "../../global/countriesAndSources";

export default function AddKeyword() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("subscriptions") === null) {
        window.location.href = '/'
    } else {
      if (JSON.parse(localStorage.getItem("subscriptions")).data === null) {
        window.location.href = '/'
      }
    }
    const [formData, setFormData] = useState({
        user_id: user.id,
        keyword: '',
        country_code: '',
        thematic: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(formData);
            await addKeyword(formData);
            alert('Keyword added successfully');
            window.location.href = '/followed';
        } catch (err) {
            alert("An error occurred while saving the keyword");
        }
    };

    const [thematics, setThematics] = useState([]);

    useEffect(() => {
        const fetchThematics = async () => {
            const resThematics = await fetchRawThematics();
            setThematics(resThematics);
        }
        fetchThematics();
    }, []);

    return (
        <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-6 text-center">Ajouter un Mot Clé</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="keyword" className="block text-gray-700 text-sm font-bold mb-2">
                        Mot Clé
                    </label>
                    <input
                        type="text"
                        id="keyword"
                        name="keyword"
                        value={formData.keyword}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="country_code" className="block text-gray-700 text-sm font-bold mb-2">
                        Pays
                    </label>
                    <select
                        id="country_code"
                        name="country_code"
                        value={formData.country_code}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                        <option value="">Sélectionner un pays</option>
                        {countries.map(country => (
                            <option key={country.code} value={country.code}>{country.name}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label htmlFor="theme" className="block text-gray-700 text-sm font-bold mb-2">
                        Thématique
                    </label>
                    <select
                        id="thematic"
                        name="thematic"
                        value={formData.thematic}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                        <option value="">Sélectionner une thématique</option>
                        {Array.isArray(thematics) && thematics.map(thematic => (
                            <option key={thematic.id} value={thematic.name}>{thematic.name}</option>
                        ))}
                    </select>
                    {thematics.length === 0 && (
                        <div className="text-center text-gray-500">Chargement des thématiques...</div>
                    )}
                </div>
                <div className="flex items-center justify-end">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Enregistrer
                    </button>
                </div>
            </form>
        </div>
    );
}