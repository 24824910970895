import React, { useState, useEffect } from "react"; 
import "./Review.css" 
import userApi, { getAllFriendsWithSharedReviews } from "../../HTTP/user"
import * as helpers from "../../Helper/utils"
import ReviewSelect from '../Review/ReviewSelect'; 
import ArticleBlocks from '../Review/ArticleBlocks'; 
import {addArticlesToReview, getPublishableReviewByUserId} from '../../HTTP/articles'; 

const user = JSON.parse(localStorage.getItem("user"))
const AddToFriendReview = () => {
    const params = new URLSearchParams(window.location.search)
    const articles = JSON.parse(decodeURIComponent(params.get('data'))) 
	articles.forEach(item => {
		delete item.publication_date;
		delete item.id;
	  });
	const [friends, setFriends] = useState([])
    const [selectedReviewId, setSelectedReviewId] = useState('');
    useEffect (() => {
        const fetchFriends = async () => {
            const friendsData = await getAllFriendsWithSharedReviews()
            setFriends(friendsData)
        }
        fetchFriends()
    }, [])
    console.log(friends)
    const handleReviewSelect = (event) => {
        setSelectedReviewId(event.target.value);
    };

    const handleAddArticles = () => {
        if (selectedReviewId) {
            console.log(`Ajout des articles à la revue avec l'ID: ${selectedReviewId}`);
            addArticlesToReview(articles, selectedReviewId).then((res) => {
                if(res === true) {
                    alert('Articles added !')
                    window.location.href = `https://static.zemus.info/?id=${selectedReviewId}`; 
                }
                else {
                    alert('Error')
                }
            })
        } else {
            console.log("Please select a review");
        }
    };

	return (
		<div className="m-4">
			<h1>Ajouter ces articles à une revue de presse existante d'un ami</h1>
            <ArticleBlocks articles={articles} />
            <select 
                className="p-2 mb-4 w-full"
                value={selectedReviewId}
                onChange={handleReviewSelect}
            >
                <option value="">Sélectionnez une revue</option>
                {friends.map((friend) => (
                    <optgroup key={friend.id} label={`${friend.lastname} ${friend.firstname}`}>
                        {friend.reviews.map((review) => (
                            <option key={review.id} value={review.id}>
                                {review.theme}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </select>
            <button className="bg-yellow-400 text-black font-bold py-2 px-4 rounded my-2" onClick={handleAddArticles}>Ajouter ces articles</button>
		</div>
	)
}

export default AddToFriendReview
