import { useEffect, useState } from "react";
import { addPriceSubscription, getAllCurrencies, getAllUsers, getNumberOfUsers } from "../../HTTP/admin";

export default function AddPriceSubscription() {
  const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.super_admin) {
        window.location.href = '/'
    }

  const [currencies, setCurrencies] = useState([]);
  const [searchCurrenciesDone, setSearchCurrenciesDone] = useState(false);

  useEffect(() => {
    const fetchCurrencies = async () => {
      const resFetchCurrencies = await getAllCurrencies();
      setCurrencies(resFetchCurrencies);
    }
    fetchCurrencies();
    setSearchCurrenciesDone(true);
  }, []);


    const [formData, setFormData] = useState({
        title: '',
        currency : 'NaN',
        price: 0,
        interval: 0,
      });

      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        }));
        console.log(formData)
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const request = {
          title: formData.title,
          currency: formData.currency,
          price: parseFloat(formData.price),
          interval: formData.interval ? 1 : 0,
        }
        try {
          const res = await addPriceSubscription(request)
          alert("Added price subscription successfully");
          window.location.href = "/admin/subscription/prices";
        } catch (err) {
          console.log(err)
          alert("An error occured while saving the price subscription");
        }
    }

    return (
        <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-6 text-center">Formulaire Prix Abonnement</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="title" className="block text-gray-700 text-sm font-bold mb-2">
                Titre
                <input type="text" value={formData.title} onChange={handleChange} className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" name="title"/>
              </label>
            </div>
            <div className="mb-4">
              <label htmlFor="price" className="block text-gray-700 text-sm font-bold mb-2">
                Prix
                <input type="number" step="0.01" value={formData.price} onChange={handleChange} className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" name="price"/>
              </label>
            </div>
            <div className="mb-4">
              <label htmlFor="currency" className="block text-gray-700 text-sm font-bold mb-2">
                Devise
              </label>
              <select value={formData.currency} onChange={handleChange} name="currency" className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
                <option value="NaN">Choisir une devise</option>
                {
                  searchCurrenciesDone && currencies.map((currency, index) => (
                    <option key={index} value={currency.code.toLowerCase()}>{currency.name}</option>
                  ))
                }
              </select>
            </div>
            <div className="mb-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="interval"
                  checked={formData.interval}
                  onChange={handleChange}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700">Paiement {!formData.interval ? "mensuel (cocher pour paiement annuel)" : "annuel (décocher pour paiement mensuel)"}</span>
              </label>
            </div>
            <div className="flex items-center justify-end">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Soumettre
              </button>
            </div>
          </form>
        </div>
    )
}