import { useEffect, useState } from "react"
import { cancelSubscription, fetchSubscriptionFromUserId } from "../../HTTP/subscription"
import { formatDateExtended, tailwindClassForColoredButton } from "../../Helper/utils"
import { deleteSubscription, fetchSubscriptionFromAllUsers, getUserById } from "../../HTTP/admin"
import TableElements from "./TableElements"
import SideBarAdmin from "./SideBarAdmin"
import ListElementsMobile from "./ListElementsMobile"

export default function AdminSubscription() {

    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.super_admin) {
        window.location.href = '/'
    }

    const [subscriptions, setSubscriptions] = useState([])
    const [subscriptionsWithUser, setSubscriptionsWithUser] = useState([])
    const [readySubs, setReadySubs] = useState(false)

    useEffect(() => {
        const funGetSubscription = async () => {
            const resSubscriptions = await fetchSubscriptionFromAllUsers()
            setSubscriptions(resSubscriptions)
            const upList = await Promise.all(
                resSubscriptions.map(async (item) => {
                  const secondResponse = await getUserById(item.user_id)
                  return {subscription: item, user: secondResponse}
                })
            )
            setSubscriptionsWithUser(upList)
        }
        funGetSubscription()
        setReadySubs(true)
    }, [])

    useEffect(() => {
        
    })

    const handleDeleteSub = async (id) => {
        if (window.confirm("Are you sure you want to delete this subscription ?")) {
            const res = await deleteSubscription(id)
            window.location.reload()
        }
    }

    const handleEditSub = (id) => {
        window.location.href = "/admin/subscription/edit/"+id
    }

    const handleAddSub = () => {
        window.location.href = "/admin/subscription/add"
    }

    const handleCancelSubscriptions = async (id) => {
        if (window.confirm("Are you sure you want to cancel this subscription ?")) {
            const res = await cancelSubscription(id)
            window.location.reload()
        }
    }
    return (
    <>
        <div className="flex mt-8">
            <div className="flex-grow">
                <h1 className="text-center mt-4 text-2xl font-bold">
                    Gestion abonnement
                </h1>
                <button
                    className={tailwindClassForColoredButton("green") + " mt-4 mb-4 ml-8"}
                    onClick={() => handleAddSub()}
                >
                    Ajouter un abonnement
                </button>
                <div className="hidden xl:block ml-8 mr-8">
                    <TableElements 
                        columns={[
                            {props:['user.firstname','user.lastname'],display:"Utilisateur",format:'fullName'}, 
                            {props:'subscription.start_date',display:"Date de début", format:'date'}, 
                            {props:'subscription.end_date',display:"Date de fin", format:'date'}, 
                            {props:'subscription.status',display:"Statut"},
                            {props:'',display:"Actions"}
                        ]}
                        data={subscriptionsWithUser}
                        buttons={[{display:'Modifier',action:handleEditSub, color:'blue', props:'subscription.id'}, {display:'Annuler l\'abonnement',action:handleCancelSubscriptions, color:'purple', props:'subscription.user_id', condition: (element) => element.subscription.status !== 'canceled'}, {display:'Supprimer',action:handleDeleteSub, color:'red', props:'subscription.id'}]}
                    />
                </div>
                <div className="xl:hidden w-full px-4 space-y-4">
                        <ListElementsMobile
                            columns={[
                                {props:['user.firstname','user.lastname'],display:"Utilisateur",format:'fullName'}, 
                                {props:'subscription.start_date',display:"Date de début", format:'date'}, 
                                {props:'subscription.end_date',display:"Date de fin", format:'date'},
                                {props:'subscription.status',display:"Statut"}, 
                                {props:'',display:"Actions"}
                            ]}
                            data={subscriptionsWithUser}
                            buttons={[{display:'Modifier',action:handleEditSub, color:'blue', props:'subscription.id'}, {display:'Annuler l\'abonnement',action:handleCancelSubscriptions, color:'purple', props:'subscription.user_id', condition:(element) => element.subscription.status !== 'canceled'}, {display:'Supprimer',action:handleDeleteSub, color:'red', props:'subscription.id'},]}
                        />
                </div>
            </div>
        </div>
    </>
    )
}