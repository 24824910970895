import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchPriceSubscriptionFromId, getAllCurrencies, updatePriceSubscription } from "../../HTTP/admin";

export default function EditPriceSubscription() {

    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.super_admin) {
        window.location.href = '/'
    }

    const [currencies, setCurrencies] = useState([]);
    const [searchCurrenciesDone, setSearchCurrenciesDone] = useState(false);
  
    useEffect(() => {
      const fetchCurrencies = async () => {
        const resFetchCurrencies = await getAllCurrencies();
        setCurrencies(resFetchCurrencies);
      }
      fetchCurrencies();
      setSearchCurrenciesDone(true);
    }, []);

    const { id } = useParams();
    const [formData, setFormData] = useState({
        title: '',
        currency : 'NaN',
        price: 0,
        interval: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchPriceSubscriptionFromId(id);
                setFormData({
                    title: data.title,
                    price: data.price,
                    currency: data.currency,
                    interval: data.interval,
                });
            } catch (err) {
                console.log(err);
                alert("An error occured while fetching the price subscription");
            }
        };
        fetchData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const request = {
            id: parseInt(id),
            title: formData.title,
            price: parseFloat(formData.price),
            currency: formData.currency,
            interval: formData.interval ? 1 : 0,
        };
        try {
            await updatePriceSubscription(request);
            alert("Price subscription updated successfully");
            window.location.href = "/admin/subscription/prices";
        } catch (err) {
            console.log(err);
            alert("An error occured while updating the price subscription");
        }
    };

    return (
        <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-6 text-center">Édition Prix Abonnement</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="title" className="block text-gray-700 text-sm font-bold mb-2">
                        Titre
                        <input type="text" value={formData.title} onChange={handleChange} className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" name="title"/>
                    </label>
                </div>
                <div className="mb-4">
                    <label htmlFor="price" className="block text-gray-700 text-sm font-bold mb-2">
                        Prix
                        <input type="number" step="0.01" value={formData.price} onChange={handleChange} className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" name="price"/>
                    </label>
                </div>
                <div className="mb-4">
              <label htmlFor="currency" className="block text-gray-700 text-sm font-bold mb-2">
                Devise
              </label>
              <select value={formData.currency} onChange={handleChange} name="currency" className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
                <option value="NaN">Choisir une devise</option>
                {
                  searchCurrenciesDone && currencies.map((currency, index) => (
                    <option key={index} value={currency.code}>{currency.name}</option>
                  ))
                }
              </select>
            </div>
            <div className="mb-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="interval"
                  checked={formData.interval}
                  onChange={handleChange}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700">Paiement {!formData.interval ? "mensuel (cocher pour paiement annuel)" : "annuel (décocher pour paiement mensuel)"}</span>
              </label>
            </div>
                <div className="flex items-center justify-end">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Soumettre
                    </button>
                </div>
            </form>
        </div>
    );
}