import React, { useState, useEffect } from "react";
import "./Admin.css";
import { getAdminCountNotifications } from "../../HTTP/admin";
import Logo from "../../media/logonobg.png";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Bars3Icon } from "@heroicons/react/24/outline";

export default function SideBarAdmin() {
    const localUser = JSON.parse(localStorage.getItem("user"));
    if (!localUser.admin) {
        window.location.href = '/';
    }

    const [countNotifications, setCountNotifications] = useState(0);
    const [isOpen, setIsOpen] = useState(window.innerWidth >= 768);

    useEffect(() => {
        const funGetCountAdminNotifications = async () => {
            const result = await getAdminCountNotifications();
            setCountNotifications(result);
        };
        funGetCountAdminNotifications();
    }, []);

    const handleDashboardAdmin = () => {
        window.location.href = "/admin";
    };

    const handleUserAdmin = () => {
        window.location.href = "/admin/users";
    };
    const handleSubscriptionAdmin = () => {
        window.location.href = "/admin/subscription";
    };
    const handleSubscriptionPricesAdmin = () => {
        window.location.href = "/admin/subscription/prices";
    };
    const handleArticlesAdmin = () => {
        window.location.href = "/admin/articles";
    };
    const handlePayments = () => {
        window.location.href = "/admin/subscription/payments";
    }
    const handleOptionsAdmin = () => {
        window.location.href = "/admin/options";
    }

    const [open, setOpen] = useState(false);
    const [openCategories, setOpenCategories] = useState({});

    const handleClick = () => {
        setOpen(!open);
    };

    const toggleCategory = (category) => {
        setOpenCategories(prev => ({
            ...prev,
            [category]: !prev[category]
        }));
    };

    useEffect(() => {
        const handleResize = () => {
            setIsOpen(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="mainsidebar">
            <button onClick={() => setIsOpen(!isOpen)} className="menuburger">
                <Bars3Icon className="h-6 w-6" />
            </button>
            <div className={`z-5 inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full w-0 overflow-hidden'} transition duration-200 ease-in-out`}>
                <List
                    sx={{ bgcolor: "#f6f4f5", width: "250px" }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            <img
                                src={Logo}
                                className="logo"
                                style={{ width: "220px", marginTop: "0", maxWidth: "80%", marginBottom: "15px" }}
                                alt="Logo"
                                onClick={() => window.location.href = "/"}
                            />
                        </ListSubheader>
                    }
                >
                    <ListItemButton className="sideMenuButton" onClick={handleDashboardAdmin}>
                        <span className="spanacc">Accueil Administration</span>
                    </ListItemButton>
                    {
                        localUser.super_admin === 1 && (
                            <>
                            <ListItemButton className="sideMenuButton" onClick={handleUserAdmin}>
                                <span className="spanacc">Utilisateurs</span>
                            </ListItemButton>
                            <ListItemButton className="sideMenuButton" onClick={handleClick}>
                            <span className="spanacc">Abonnement</span>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton className="sideMenuButton" sx={{ pl: 4 }} onClick={handleSubscriptionAdmin}>
                                    <span className="spanacc">Utilisateurs abonnés</span>
                                </ListItemButton>
                                <ListItemButton className="sideMenuButton" sx={{ pl: 4 }} onClick={handleSubscriptionPricesAdmin}>
                                    <span className="spanacc">Tarifs</span>
                                </ListItemButton>
                                <ListItemButton className="sideMenuButton" sx={{ pl: 4 }} onClick={handlePayments}>
                                    <span className="spanacc">Historique des paiements</span>
                                </ListItemButton>
                            </List>
                    </Collapse>
                            </>
                        )
                    }
                    
                    <ListItemButton className="sideMenuButton" onClick={handleArticlesAdmin}>
                        <span className="spanacc">Articles</span>
                    </ListItemButton>
                    {
                        localUser.super_admin === 1 && (
                            <ListItemButton className="sideMenuButton" onClick={handleOptionsAdmin}>
                                <span className="spanacc">Options</span>
                            </ListItemButton>
                        )
                    }
                </List>
            </div>
        </div>
    );
}