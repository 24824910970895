import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { isUserSubscribed } from '../../HTTP/user';
import { getPublicKeyStripe } from '../../HTTP/payment';

// ... StatusIcon component reste inchangé ...

const NextPagePayment = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('processing');
  const [message, setMessage] = useState('');

  const handleSubscription = async () => {
    const res = await isUserSubscribed(JSON.parse(localStorage.getItem("user")).id);
    if (res) {
      localStorage.setItem("subscriptions", JSON.stringify(res));
    }
  }

  useEffect(() => {
    const initializePayment = async () => {
      const clientSecret = searchParams.get('payment_intent_client_secret');
      
      if (!clientSecret) {
        setStatus('error');
        setMessage('Aucune information de paiement trouvée.');
        return;
      }

      try {
        // Récupérer d'abord la clé Stripe
        const response = await getPublicKeyStripe();
        const stripeKey = response.value;

        // Initialiser Stripe avec la clé récupérée
        const stripe = await loadStripe(stripeKey);
        
        if (!stripe) {
          throw new Error('Échec de l\'initialisation de Stripe');
        }

        // Récupérer le PaymentIntent
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
        
        switch (paymentIntent.status) {
          case 'succeeded':
            await handleSubscription();
            setStatus('success');
            setMessage('Paiement réussi !');
            break;
          case 'processing':
            setStatus('processing');
            setMessage('Votre paiement est en cours de traitement.');
            break;
          case 'requires_payment_method':
            setStatus('error');
            setMessage('Le paiement a échoué. Veuillez réessayer.');
            break;
          default:
            setStatus('error');
            setMessage('Une erreur est survenue.');
            break;
        }
      } catch (error) {
        console.error('Erreur lors de l\'initialisation du paiement:', error);
        setStatus('error');
        setMessage('Une erreur est survenue lors de l\'initialisation du paiement.');
      }
    };

    initializePayment();
  }, [searchParams]);

  const statusContent = {
    processing: {
      title: 'Paiement en cours...',
      description: 'Veuillez patienter pendant que nous traitons votre paiement.',
      bgColor: 'bg-blue-50',
    },
    success: {
      title: 'Paiement réussi !',
      description: message,
      bgColor: 'bg-green-50',
    },
    error: {
      title: 'Erreur de paiement',
      description: message,
      bgColor: 'bg-red-50',
    },
  };

  const content = statusContent[status];

  return (
    <div className="min-h-screen flex items-center justify-center p-4 mt-8">
      <div className={`w-full max-w-md ${content.bgColor} rounded-lg shadow-lg transition-all duration-300 ease-in-out`}>
        <div className="flex flex-col items-center text-center p-8">
          
          <h1 className="text-2xl font-bold mb-4 text-gray-800">
            {content.title}
          </h1>
          
          <p className="text-gray-600 text-lg">
            {content.description}
          </p>

          {status === 'error' && (
            <button 
              className="mt-6 px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
              onClick={() => window.location.href = '/'}
            >
              Retour à l'accueil
            </button>
          )}
          
          {status === 'success' && (
            <button 
              className="mt-6 px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors duration-200"
              onClick={() => window.location.href = '/'}
            >
              Revenir sur Zemus
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NextPagePayment;