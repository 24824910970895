import { BrowserRouter, Routes, Route, generatePath } from "react-router-dom";
import { useState, useEffect } from "react";
import { Context } from "./Helper/Context";
import './App.css';
import GeneralContainerWrapper, { AdminContainerWrapper } from "./global/GeneralContainer"
import Feed from "./Component/Feed/Feed"
import MyArticles from "./Component/Articles/myArticles";
import Articlesfav from "./Component/Home/articlesfav";
import ArticlesFavByReview from "./Component/Home/articlesfavbyreview";
import Friends from "./Component/Friend/Friends";
import FriendProfile from "./Component/Profile/Friend";
import Followed from "./Component/Suivis/suivis";
import FriendsMessages from "./Component/Listmsg/Listmsg";
import Profile from "./Component/Profile/Profile";
import ProfileEdit from "./Component/Profile/Edit";
import GoogleCSE from "./Component/Home/GoogleCSE"
import Articles from "./Component/Home/articles"
import Article from "./Component/Review/Article"
import Login from "./Component/Login/Login"
import WhatThisCountryTalkAbout from "./Component/Home/WhatThisCountryTalkAbout";
import TheCountrySpeaksOf from "./Component/Home/TheCountrySpeaksOf";
import WhichCountriesSpeakAbout from "./Component/Home/WhichCountriesSpeakAbout";
import Subscription from "./Component/Subscription/Subscription";
import Conditions from "./Component/CONDITIONS/Conditions";
import Legales from "./Component/CONDITIONS/Legales";
import POLITIQUE from "./Component/CONDITIONS/POLITIQUE";
//import { countries } from './global/countriesAndSources'
import Conversation from './Component/Conversation/Conversation'
import Audiocall from './Component/call/audiocall'
import VideoCall from './Component/call/videocall'
import Map from './Component/Map/Map'
import Review from './Component/Review/Review'
import EditReview from './Component/Review/EditReview'
import AddToReview from './Component/Review/AddToReview'
import AddToFriendReview from "./Component/Review/AddToFriendReview";
import { HelmetProvider } from 'react-helmet-async';
import Admin from "./Component/Admin/Admin";
import ReviewAdmin from "./Component/Admin/ReviewAdmin";
import UserAdmin from "./Component/Admin/UserAdmin";
import EditUser from "./Component/Admin/EditUser";
import EditReviewAdmin from "./Component/Admin/EditReview";
import ArticlesFavAdmin from "./Component/Admin/ArticlesFavAdmin";
import AddUser from "./Component/Admin/AddUser";
import ArticlesInReviewAdmin from "./Component/Admin/ArticlesInReviewAdmin";
import AddReview from "./Component/Admin/AddReview";
import AddFavorite from "./Component/Admin/AddFavorite";
import MyArticlesCreate from "./Component/Articles/myArticlesCreate";
import MyArticlesEdit from "./Component/Articles/myArticlesEdit";
import ArticlesView from "./Component/Articles/myArticlesView";
import ArticlesAdmin from "./Component/Admin/ArticlesAdmin";
import Notifications from "./Component/Home/notifications";
import AdminNotifications from "./Component/Admin/NotificationAdmin";
import EditArticleAdmin from "./Component/Admin/EditArticle";
import CheckArticle from "./Component/Admin/checkArticle";
import UserSubscription from "./Component/Subscription/userSubscription";
import AdminSubscription from "./Component/Admin/SubscriptionAdmin";
import AddSubscription from "./Component/Admin/AddSubscription";
import EditSubscription from "./Component/Admin/EditSubscription";
import AdminPriceSubscription from "./Component/Admin/PriceSubscriptionAdmin";
import AddPriceSubscription from "./Component/Admin/AddPriceSubscription";
import EditPriceSubscription from "./Component/Admin/EditPriceSubscription";
import SettingsSubscriptionAdmin from "./Component/Admin/SettingsSubscriptionAdmin";
import OptionsAdmin from "./Component/Admin/OptionsAdmin";
import PaymentsAdmin from "./Component/Admin/PaymentsAdmin";
import { CheckoutForm } from "./Component/Subscription/PaymentForm";
import NextPagePayment from "./Component/Subscription/NextPagePayment";
import EditKeyword from "./Component/Suivis/editKeyword";
import AddKeyword from "./Component/Suivis/addKeyword";

let W_MYARTICLES = GeneralContainerWrapper(MyArticles)
let W_MYARTICLESCREATE = GeneralContainerWrapper(MyArticlesCreate)
let W_MYARTICLESEDIT = GeneralContainerWrapper(MyArticlesEdit)
let W_ARTICLECHECK = AdminContainerWrapper(CheckArticle)
let W_ARTICLES = GeneralContainerWrapper(Articles)
let W_ARTICLES_FAV = GeneralContainerWrapper(Articlesfav)
let W_ARTICLES_FAV_BY_REVIEW = GeneralContainerWrapper(ArticlesFavByReview)
let W_ARTICLE = GeneralContainerWrapper(Article)
let W_GOOGLE_CSE = GeneralContainerWrapper(GoogleCSE)
let W_WHAT_THIS_COUNTRY_TALK_ABOUT = GeneralContainerWrapper(WhatThisCountryTalkAbout)
let W_THE_COUNTRY_SPEAKS_OF = GeneralContainerWrapper(TheCountrySpeaksOf)
let W_WHICH_COUNTRIES_SPEAK_ABOUT = GeneralContainerWrapper(WhichCountriesSpeakAbout)
let W_FEED = GeneralContainerWrapper(Feed)
let W_FRIENDS = GeneralContainerWrapper(Friends)
let W_FRIEND_PROFILE = GeneralContainerWrapper(FriendProfile)
let W_FRIENDS_MESSAGES = GeneralContainerWrapper(FriendsMessages)
let W_PROFILE = GeneralContainerWrapper(Profile)
let W_EDIT = GeneralContainerWrapper(ProfileEdit)
let W_FOLLOWED = GeneralContainerWrapper(Followed)
let W_AUDIOCALL = GeneralContainerWrapper(Audiocall)
let W_VIDEOCALL = GeneralContainerWrapper(VideoCall)
let W_CONVERSATION = GeneralContainerWrapper(Conversation)
let W_MAP = GeneralContainerWrapper(Map)
let W_REVIEW = GeneralContainerWrapper(Review)
let W_ADDTOREVIEW = GeneralContainerWrapper(AddToReview)
let W_ADDTOFRIENDREVIEW = GeneralContainerWrapper(AddToFriendReview)
let W_EDITREVIEW = GeneralContainerWrapper(EditReview)
let W_ADMIN = AdminContainerWrapper(Admin)
let W_USERADMIN = AdminContainerWrapper(UserAdmin)
let W_EDITUSERADMIN = AdminContainerWrapper(EditUser)
let W_REVIEWADMIN = (ReviewAdmin)
let W_EDITREVIEWADMIN = (EditReviewAdmin)
let W_ARTICLESFAVADMIN = (ArticlesFavAdmin)
let W_ADDUSERADMIN = AdminContainerWrapper(AddUser)
let W_ARTICLESINREVIEWADMIN = (ArticlesInReviewAdmin)
let W_ADDREVIEWADMIN = (AddReview)
let W_ADDFAVORITEADMIN = (AddFavorite)
let W_ARTICLESADMIN = AdminContainerWrapper(ArticlesAdmin)
let W_EDITARTICLEADMIN = (EditArticleAdmin)
let W_NOTIFICATIONS = GeneralContainerWrapper(Notifications)
let W_ADMINNOTIFICATIONS = AdminContainerWrapper(AdminNotifications)
let W_ADMINSUBSCRIPTION = AdminContainerWrapper(AdminSubscription)
let W_ADMINADDSUBSCRIPTION = AdminContainerWrapper(AddSubscription)
let W_ADMINEDITSUBSCRIPTION = AdminContainerWrapper(EditSubscription)
let W_ADMINPRICESUBSCRIPTION = AdminContainerWrapper(AdminPriceSubscription)
let W_ADMINADDPRICESUBSCRIPTION = AdminContainerWrapper(AddPriceSubscription)
let W_ADMINEDITPRICESUBSCRIPTION = AdminContainerWrapper(EditPriceSubscription)
let W_ADMINSETTINGSSUBSCRIPTION = AdminContainerWrapper(SettingsSubscriptionAdmin)
let W_ADMINOPTIONS = AdminContainerWrapper(OptionsAdmin)
let W_ADMINPAYMENTS = AdminContainerWrapper(PaymentsAdmin)
let W_PAYSUBSCRIPTION = GeneralContainerWrapper(CheckoutForm)
let W_NEXTPAGEPOSTPAYMENT = GeneralContainerWrapper(NextPagePayment)
let W_USER_SUBSCRIPTION = GeneralContainerWrapper(UserSubscription)
let W_ADDKEYWORDS = GeneralContainerWrapper(AddKeyword)
let W_EDITKEYWORDS = GeneralContainerWrapper(EditKeyword)

function App() {
  const [Fil, SetFil] = useState("fil");
  const [selection, setselection] = useState(false);
  const [publicationID, setPublicationID] = useState(false);
  const [friendAction, setFriendAction] = useState(false)

  useEffect(() => {
      document.title = "Zemus - Locate the news"
  }, [])


  // Redirection before connection
  if (window.location.href.split("/")[3] !== "reviews" && window.location.href.split("/")[3] !== "login" && (!localStorage.getItem("token") || !localStorage.getItem("token_age")) ) {
      window.location.href = "/login"
  }
  // Si le token est trop vieux, on enlève tout du local storage, donc au prochain passage dans le routeur : redirection
  if (localStorage.getItem("token_age") && new Date().getTime() - parseInt(localStorage.getItem("token_age")) > 28800000) { // Différence de 8 heures en millisecondes
    localStorage.removeItem("token")
    localStorage.removeItem("token_age")
    localStorage.removeItem("user")
  }
  return (
    <HelmetProvider>
      <Context.Provider
        value={{
          Fil,
          SetFil,
          selection,
          setselection,
          publicationID,
          setPublicationID,
          friendAction,
          setFriendAction
        }}
      >

          <div className="App">
          <BrowserRouter>
              <Routes>
                <Route path="/" element={<W_WHAT_THIS_COUNTRY_TALK_ABOUT />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/admin" element={<W_ADMIN />}></Route>
                <Route path="/admin/options" element={<W_ADMINOPTIONS />}></Route>
                <Route path="/admin/users" element={<W_USERADMIN />}></Route>
                <Route path="/admin/users/add" element={<W_ADDUSERADMIN />}></Route>
                <Route path="/admin/users/edit/:id" element={<W_EDITUSERADMIN />}></Route>
                {/* <Route path="/admin/reviews" element={<W_REVIEWADMIN />}></Route>
                <Route path="/admin/reviews/add" element={<W_ADDREVIEWADMIN />}></Route>
                <Route path="/admin/reviews/articles/:id" element={<W_ARTICLESINREVIEWADMIN />}></Route>
                <Route path="/admin/reviews/edit/:id" element={<W_EDITREVIEWADMIN />}></Route>
                <Route path="/admin/favorites" element={<W_ARTICLESFAVADMIN />}></Route>
                <Route path="/admin/favorites/add" element={<W_ADDFAVORITEADMIN />}></Route> */}
                <Route path="/admin/articles" element={<W_ARTICLESADMIN />}></Route>
                <Route path="/admin/articles/check/:id" element={<W_ARTICLECHECK />}></Route>
                <Route path="/admin/notifications" element={<W_ADMINNOTIFICATIONS />}></Route>
                <Route path="/admin/subscription" element={ <W_ADMINSUBSCRIPTION /> }> </Route>
                <Route path="/admin/subscription/add" element={ <W_ADMINADDSUBSCRIPTION /> }> </Route>
                <Route path="/admin/subscription/edit/:id" element={ <W_ADMINEDITSUBSCRIPTION /> }> </Route>
                <Route path="/admin/subscription/settings" element={ <W_ADMINSETTINGSSUBSCRIPTION /> }> </Route>
                <Route path="/admin/subscription/prices" element={ <W_ADMINPRICESUBSCRIPTION /> }> </Route>
                <Route path="/admin/subscription/prices/add" element={ <W_ADMINADDPRICESUBSCRIPTION /> }> </Route>
                <Route path="/admin/subscription/prices/edit/:id" element={ <W_ADMINEDITPRICESUBSCRIPTION /> }> </Route>
                <Route path="/admin/subscription/payments" element={ <W_ADMINPAYMENTS /> }> </Route>
                <Route path="/search" element={ <W_WHAT_THIS_COUNTRY_TALK_ABOUT/> }> </Route>
                <Route path="/:countrycode/what-this-country-talk-about" element={ <W_WHAT_THIS_COUNTRY_TALK_ABOUT/> }> </Route>
                <Route path="/:countrycode/the-country-speaks-of" element={ <W_THE_COUNTRY_SPEAKS_OF/> }> </Route>
                <Route path="/:countrycode/which-countries-speak-about" element={ <W_WHICH_COUNTRIES_SPEAK_ABOUT/> }> </Route>
                <Route path="/search" element={ <W_WHAT_THIS_COUNTRY_TALK_ABOUT/> }> </Route>
                <Route path="/articles" element={ <W_ARTICLES /> }> </Route>
                <Route path="/articles/:articleid" element={ <W_ARTICLE /> }> </Route>
                <Route path="/myarticles" element={ <W_MYARTICLES /> }> </Route>
                <Route path="/myarticles/create" element={ <W_MYARTICLESCREATE /> }> </Route>
                <Route path="/myarticles/edit/:id" element={ <W_MYARTICLESEDIT /> }> </Route>
                <Route path="/notifications" element= { <W_NOTIFICATIONS /> }></Route>
                <Route path="/feed" element={ <W_FEED/> }> </Route>
                <Route path="/favourites" element={ <W_ARTICLES_FAV/> }> </Route> 
                <Route path="/friends" element={ <W_FRIENDS/> }> </Route>
                <Route path="/friends/:id" element={ <W_FRIEND_PROFILE/> }> </Route>
                <Route path="/followed" element={ <W_FOLLOWED/> }> </Route>
                <Route path="/editkeywords/:id" element={ <W_EDITKEYWORDS /> }> </Route>
                <Route path="/addkeywords" element={ <W_ADDKEYWORDS /> }> </Route>
                <Route path="/friends-msg" element={ <W_FRIENDS_MESSAGES/> }> </Route>
                <Route path="/profile" element={ <W_PROFILE/> }> </Route>
                <Route path="/profile/subscription" element={ <W_USER_SUBSCRIPTION /> }> </Route>
                <Route path="/edit" element={ <W_EDIT/> }> </Route>
                <Route path="/conversation" element={ <W_CONVERSATION/> }> </Route>
                <Route path="/audiocall" element={ <W_AUDIOCALL/> }> </Route>
                <Route path="/videocall" element={ <W_VIDEOCALL/> }> </Route>

                <Route path="/Subscription" element={<Subscription />}></Route>
                <Route path="/addsubscription" element={<W_PAYSUBSCRIPTION />}></Route>
                <Route path="/nextpagepayment" element={<W_NEXTPAGEPOSTPAYMENT />}></Route>
                <Route path="/Conditions" element={<Conditions />}></Route>
                <Route path="/Legales" element={<Legales />}></Route>
                <Route path="/POLITIQUE" element={<POLITIQUE />}></Route>
                <Route path="/map" element={<W_MAP />}></Route>
                <Route path="/reviews/:reviewid" element={<W_REVIEW />}></Route>
                <Route path="/reviews/:reviewid/addfavourites" element={<W_ARTICLES_FAV_BY_REVIEW />}></Route>
                <Route path="/addtoreview" element={<W_ADDTOREVIEW />}></Route> 
                <Route path="/addtofriendreview" element={<W_ADDTOFRIENDREVIEW />}></Route>
                <Route path="/editreviews/:reviewid" element={<W_EDITREVIEW />}></Route>
              </Routes>
            </BrowserRouter>
          </div>

    </Context.Provider>
    </HelmetProvider>
  );
}

export default App;


