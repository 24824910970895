import React, { useState, useEffect} from "react";
import "./Profile.css"
import verificato from "../../media/badge.png";
import userApi from "../../HTTP/user"
import ReviewBlocks from '../Review/ReviewBlocks'; // Assurez-vous que le chemin d'importation est correct
import { useParams } from 'react-router-dom';
import { getArticlesByAuthorId } from "../../HTTP/articles";
import { escapeQuotes, formatDateExtended, removeHtmlTags } from "../../Helper/utils";
import Pagination from "../../Helper/Pagination";
import imgArticle from "../../media/ICONS THEMATIQUE/Fichier 5_1.png"
import { use } from "react";

const Friend = () => {
	let { id } = useParams(); // Assurez-vous que le nom du paramètre correspond à celui défini dans votre route 
	const [friend, setFriend] = useState([]);

	const url = new URL(window.location.href)
	if (url.searchParams.get('articlepage') === null) {
		url.searchParams.set('articlepage', 1);
		window.history.pushState({}, '', url);
	}
	
	useEffect(() => {
		userApi.getFriend(id).then((f) => setFriend(f))
	}, [id]);

	const [reviews, setReviews] = useState([]); 
	const [articles, setArticles] = useState({count:0, rows:[]});
	const [currentArticlePage, setCurrentArticlePage] = useState(parseInt(url.searchParams.get('articlepage')));
	useEffect(() => {
		const fetchReviews = async () => {
		  const reviewsData = await userApi.getUserReviews(id); // Assurez-vous que ceci renvoie un tableau 
		  setReviews(reviewsData.reviews);
		};
	  
		fetchReviews();
	  }, []);
	
	useEffect(() => {
		const fetchArticles = async () => {
			const articlesData = await getArticlesByAuthorId(id, (currentArticlePage - 1) * 3, currentArticlePage * 3, "" , "title", 4);
			console.log(articlesData)
			setArticles(articlesData);
		  }
		  fetchArticles();
	}, [currentArticlePage]);

	console.log(articles)
	 
	const ArticleCard = ({ imageUrl, title, text, date }) => (
		<div className="bg-white rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl h-full">
		  <div className="flex flex-col h-full">
			<img
			  className="w-full h-48 object-cover flex-shrink-0"
			  src={imageUrl}
			  alt={title}
			/>
			<div className="p-4 flex-grow flex flex-col">
			  <h3 className="text-lg font-semibold mb-2">{title}</h3>
			  <p className="text-gray-600 flex-grow">
				{text.length > 150 ? text.slice(0, 50) + "..." : text}
			  </p>
			  <div className="flex items-center mb-2">
				<p className="text-sm mt-2 notranslate">
				  {formatDateExtended(date)}
				</p>
			  </div>
			</div>
		  </div>
		</div>
	);

	const articlePagination = (pageNumber) => {
		url.searchParams.set('articlepage', pageNumber);
		window.history.pushState({}, '', url);
		setCurrentArticlePage(pageNumber);
	}

	return (
		<div>
		<div className="profileHeader md:flex mb-5 md:mt-8">
			<div className="profileHeader-element">  
				<img src={friend.profile_picture || process.env.PUBLIC_URL+"/profile_default.png" } alt="profile" className="profilePicture" />  
			</div>
			<div className="profileHeader-element">  
			<h2 className="notranslate"> 
				{ `${friend?.firstname} ${friend?.lastname}` }
				<img className="verificato" src={verificato} alt="verification" />
			</h2>
				<div className="md:flex">
					<p className="notranslate"><img src={process.env.PUBLIC_URL+"/sidebar/tel.png"} alt="Phone" className="profilePicto"  />{ `${friend?.phone}` }</p>
					<p className="notranslate"><img src={process.env.PUBLIC_URL+"/sidebar/mail_white.png"} alt="email" className="profilePicto" />{ `${friend?.email}` }</p>
				</div>
				<p><img src={process.env.PUBLIC_URL+"/sidebar/terre.png"} alt="Country" className="profilePicto"  />{ `${friend?.country}` }</p>
				<p><img src={process.env.PUBLIC_URL+"/sidebar/pin.png"} alt="Address" className="profilePicto"  />{ `${friend?.address}` } </p>    
			</div> 
			<div className="profileHeader-element">  
				<div className="mt-2 pl-5 pr-5 md:ml-5 border-l-2 border-white profileHeader-bio">
					<h3 className="font-bold mb-2">Biographie</h3>
					<p>{ `${friend?.bio}` } </p>
				</div>
			</div>
		</div>
		<div className='profileContainer'> 
			<h1 className="ml-4">Revues de presse</h1> 
			<ReviewBlocks reviews={reviews} edition="false" displayAuthor={false}/>
		</div>
		<div className='profileContainer'>
			<h1 className="ml-4">Articles</h1>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
				{articles.rows.map((article, i) => (
					<ArticleCard
						key={i}
						imageUrl={imgArticle}
						title={article.title}
						text={removeHtmlTags(article.description)}
						date={article.created_at}
					/>
				))}
			</div>
			</div>
			<Pagination
				itemsPerPage={3}
				totalItems={articles.count}
				paginate={articlePagination}
				currentPage={currentArticlePage}
			/>
		</div>
	)
}

export default Friend
