import { useEffect, useState } from "react"
import { getAllUsers, getNumberOfUsers, removeUser, getUsersByQuery, getNumberOfQueryUsers } from "../../HTTP/admin"
import { ArrowLeft } from "@mui/icons-material";
import { countries } from "../../global/countriesAndSources";
import TableElements from './TableElements';
import ListElementsMobile from "./ListElementsMobile";
import SearchComponent from "./SearchComponent";
import Pagination from "../../Helper/Pagination";
import Side from "../sidecontainer/side";
import SideBarAdmin from "./SideBarAdmin";

export default function UserAdmin() {
    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.super_admin) {
        window.location.href = '/'
    }
    const url = new URL(window.location.href)
    if (url.searchParams.get('page') === null) {
        url.searchParams.set('page', 1);
        window.history.pushState({}, '', url);
    }
    if (url.searchParams.get('country') === null) {
        url.searchParams.set('country', 'ac');
        window.history.pushState({}, '', url);
    }

    const [users, setUsers] = useState([])
    const [searchTerm, setSearchTerm] = useState(url.searchParams.get('query') === null ? '' : url.searchParams.get('query'));
    const [searchDone, setSearchDone] = useState(false)
    const [country, setCountry] = useState(url.searchParams.get('country') === null ? '' : url.searchParams.get('country'));
    const [currentPage, setCurrentPage] = useState(parseInt(url.searchParams.get('page')));
    const [usersPerPage] = useState(6);
    const [nbUsers, setNbUsers] = useState(0);

    useEffect(() => {
        const funNumberUsers = async () => {
            if (searchTerm !== '') {
                const numberUsers = await getNumberOfQueryUsers(searchTerm, country)
                setNbUsers(numberUsers)
            } else {
                const numberUsers = await getNumberOfUsers(country)
                setNbUsers(numberUsers)
            }
        }
        funNumberUsers()
        const funSearchUsers = async () => {
            if (searchTerm !== '') {
                const searchUsers = await getUsersByQuery((currentPage - 1) * usersPerPage, currentPage * usersPerPage, searchTerm, country)
                setUsers(searchUsers)
            } else {
                const searchUsers = await getAllUsers((currentPage - 1) * usersPerPage, currentPage * usersPerPage, country)
                setUsers(searchUsers)
            }
        }
        funSearchUsers()
        setSearchDone(true)
    }, [])
    
    // Change page
    const paginate = (pageNumber) => {
        window.location.href = "/admin/users?page=" + pageNumber + (url.searchParams.get("query") === null ? '' : "&query=" + url.searchParams.get("query")) + (url.searchParams.get("country") === null ? '' : "&country=" + url.searchParams.get("country"))
    }

    const handleQuery = (e) => {
        e.preventDefault()
        if (url.searchParams.get('query') === null) {
            url.searchParams.set('query', searchTerm);
            window.history.pushState({}, '', url);
        }
        window.location.href = "/admin/users?page=1&query=" + searchTerm + "&country=" + country
    }

    const handleEditUser = (id) => {
        window.location.href = "/admin/users/edit/" + id
    }

    const handleAddUser = () => {
        window.location.href = "/admin/users/add"
    }

    const handleBackToDashboard = () => {
        window.location.href = "/admin";
      };
    
    const handleChangeCountry = (event) => {
        window.location.href = "/admin/users?page=1&query=&country="  + event.target.value
    }

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this user ?")) {
            removeUser(id).then(() => alert("User deleted !")).catch((err) => alert("Error while deleting user : ", err))
            window.location.href = "/admin/users"
        } else {
            alert("Canceled deletion");
        }
    }

    return (
        <div className="flex flex-row mt-8">
            <div className="flex-grow px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col sm:flex-row items-center justify-normal sm:justify-between mb-6 mt-8 xl:mt-0">
                    <h1 className="text-2xl font-bold text-center flex-grow mt-2">
                        Modération des utilisateurs
                    </h1>
                </div>
                <SearchComponent
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    handleQuery={handleQuery}
                    dropdownList={localUser.super_admin === 1 ? {select:'Pays', value:country, handle:handleChangeCountry} : {}}
                    otherButton={{title:'Ajouter', handle:handleAddUser}}
                />
                <div className='hidden xl:block'>
                    <TableElements 
                        columns={[{props:'lastname',display:"Nom"}, 
                        {props:'firstname',display:"Prénom"}, 
                        {props:'email',display:"Email"}, 
                        {props:'country',display:"Pays", format:'country'}, 
                        {props:'',display:"Actions"}
                        ]}
                        data={users}
                        buttons={[{display:'Éditer',action:handleEditUser, color:'blue', props:'id'}, 
                        {display:'Supprimer',action:handleDelete, color:'red', props:'id'}]}
                    />
                </div>
                <div className="xl:hidden w-full px-4 space-y-4">
                    <ListElementsMobile 
                        columns={[{props:'lastname',display:"Nom"}, 
                        {props:'firstname',display:"Prénom"}, 
                        {props:'email',display:"Email"}, 
                        {props:'country',display:"Pays", format:'country'}, 
                        {props:'',display:"Actions"}
                        ]}
                        data={users}
                        buttons={[{display:'Éditer',action:handleEditUser, color:'blue', props:'id'}, 
                        {display:'Supprimer',action:handleDelete, color:'red', props:'id'}]}
                        little={true}
                    />
                </div>
                {searchDone && <Pagination
                    itemsPerPage={usersPerPage}
                    totalItems={nbUsers}
                    paginate={paginate}
                    currentPage={currentPage}
                />}
            </div>
        </div>
    )
}