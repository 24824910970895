import { getNestedValue, tailwindClassForColoredButton } from "../../Helper/utils";

export default function ElementMobile({ column, element, buttons }) {
    // Fonction pour vérifier si le bouton doit être affiché
    const shouldShowButton = (button) => {
        // Si la condition n'est pas définie, on affiche toujours le bouton
        if (button.condition === undefined || button.condition === null) {
            return true;
        }
        
        // Si la condition est une fonction, on l'évalue avec l'élément
        if (typeof button.condition === 'function') {
            return button.condition(element);
        }
        
        // Sinon, on retourne la valeur directe de la condition
        return button.condition;
    };

    return (
        <div className="bg-white p-4 rounded-lg shadow-md mb-2">
            <div className="space-y-3">
                {column.map((col, index) => (
                    col.display === "Actions" ? (
                        <div key={`${col.props}-${index}`} className="py-2">
                            <div className="flex flex-col space-y-2">
                                {buttons.map((b, buttonIndex) => (
                                    shouldShowButton(b) && (
                                        <button
                                            key={`${b.display}-${buttonIndex}`}
                                            className={tailwindClassForColoredButton(b.color)}
                                            onClick={() => b.action(getNestedValue(element, b))}
                                        >
                                            {b.display}
                                        </button>
                                    )
                                ))}
                            </div>
                        </div>
                    ) : (
                        <p key={`${col.props}-${index}`} className="mb-2">
                            <span className="font-medium">{col.display} : </span>
                            {getNestedValue(element, col)}
                        </p>
                    )
                ))}
            </div>
        </div>
    );
}