import authHeader from "./auth.header"
import constants from './constants.js'
import { escapeQuotes } from "../Helper/utils.js";

const HOST_URL = constants.API_SCHEME + "://" + constants.API_HOST_NAME + ":" + constants.API_PORT;

export const getKeywordById = async (keywordId) => {
    const response = await fetch(HOST_URL + '/keywords/' + keywordId, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        }
    })
    const data = await response.json()
    return data
}

export const fetchKeywordsFromUserId = async (userId, firstElement, lastElement, query) => {
    let response
    if (query === '') {
        response = await fetch(HOST_URL + `/keywords/user/${userId}/${firstElement}/${lastElement}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
        })
    } else {
        response = await fetch(HOST_URL + `/keywords/user/${userId}/${firstElement}/${lastElement}/${query}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
        })
    }
    const data = await response.json()
    return data
}

export const addKeyword = async (keyword) => {
    console.log(HOST_URL)
    const response = await fetch(HOST_URL + '/keywords/add', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(keyword)
    })
    const data = await response.json()
    console.log(data)
    return data
}

export const deleteKeyword = async (keywordId) => {
    const response = await fetch(HOST_URL + '/keywords/delete/' + keywordId, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        }
    })
    const data = await response.json()
    return data
}

export const updateKeyword = async (keyword) => {
    const response = await fetch(HOST_URL + '/keywords/update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(keyword)
    })
    const data = await response.json()
    return data
}