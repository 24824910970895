import constants from './constants.js'
import {isUserSubscribed} from './user.js'

const HOST_URL = constants.API_SCHEME + "://" + constants.API_HOST_NAME + ":" + constants.API_PORT;
const REACT_APP_API = constants.REACT_APP_API
const scheme = constants.API_SCHEME;


/**
 * @param {{
    * lastname: string,
    * fistname: string,
    * email: string,
    * country?: string 
    * password: string}} userInfo
    * 
 * @returns {Promise<{}>}
*/
async function registration(userInfo) {
    let response = await fetch(HOST_URL + "/users", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(userInfo)
    })
    .catch((err) => { console.log("HTTP caught in promise catch", err) })

    if (!response) {
        return Promise.reject({ message: "Une erreur inconnue est survenue" })
    }

    const jsonResponse = await response.json()
    console.log(jsonResponse)

    if (!response.ok) {
       return Promise.reject(response.status === "422" ? jsonResponse.errorList[0] : jsonResponse.message)
    }

    return Promise.resolve("Votre compte a bien été créé !")
}



/**
 * @param {string} email 
 * @param {string} password 
 * @returns {promise<void|error>}
**/
const login = async (email, password) => {
    console.log("HOST_URL", HOST_URL)
    console.log("REACT_APP_API", REACT_APP_API)
    console.log("scheme", scheme)

    let response = await fetch(HOST_URL + "/sign-in", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({ email: email, password: password })
    })
    .catch((err) => {
        console.error(err)
    })

    if (!response) {
        return Promise.reject({ message: "Une erreur inconnue est survenue." })
    }

    if (!response.ok) { 
        return Promise.reject(await response.json())
    }

    response = await response.json()
    localStorage.setItem("token", response.token)
    localStorage.setItem("token_age", new Date().getTime())
    localStorage.setItem("user", JSON.stringify(response.user))
    localStorage.setItem("favorites", JSON.stringify(response.favorites))
    // const sub = await isUserSubscribed(response.user.id)
    // if (sub) {
    //     sessionStorage.setItem("subscriptions", JSON.stringify(sub))
    // }
    return Promise.resolve()
}


const logout = () => {
    localStorage.removeItem("user")
    localStorage.removeItem("token")
    localStorage.removeItem("token_age")
    localStorage.removeItem("favorites")
    localStorage.removeItem("subscriptions")
}

// const getCurrentUser = () => {
//     return JSON.parse(localStorage.getItem("user"))
// }

const AuthService = {
    login,
    logout,
    registration,
}

export default AuthService;
