import React, { useState, useEffect, useContext } from "react";
import "./friend.css";
import amis from "../../media/amis.png";
import cancel from "../../media/cancel.svg";
import heart from "../../media/heart.png";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Context } from "../../Helper/Context";
import userApi from "../../HTTP/user"
import * as helpers from "../../Helper/utils"; // Importez tout le module helper

const Friend = () => {

  const [searchTerm, setSearchTerm] = useState("");
  const [friends, setFriends] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const {friendAction, setFriendAction } = useContext(Context);
  const [publicUsers, setPublicUsers] = useState([]); // Ajout de l'état pour les utilisateurs publics
  
  useEffect(() => {
      userApi.getAllFriends().then((f) => setFriends(f))
  }, [friendAction])

  useEffect(() => {
    // Appel pour obtenir tous les utilisateurs publics
    const fetchPublicUsers = async () => {
        try {
            const users = await userApi.getSuggestedFriends(); 
            setPublicUsers(users);
            console.log(users);
        } catch (error) {
            console.error('Erreur lors de la récupération des utilisateurs publics', error);
        }
    };

    const fetchFriendsRequests = async () => {
      try {
          const requests = await userApi.getFriendRequests();
          // console.log(requests.length+' / '+friendRequests.length)
          // if (requests.length > friendRequests.length) { 
          //   alert('Vous avez de nouvelles invitations !');
          // }
          
          setFriendRequests(requests); 
      } catch (error) {
          console.error('Erreur lors de la récupération des demandes d\'ami reçues', error);
      }
    };

    const fetchInvitations = async () => {
      try {
          const invitations = await userApi.getInvitations();
          setInvitations(invitations) 
      } catch (error) {
          console.error('Erreur lors de la récupération des demandes d\'ami envoyées', error);
      }
   };

    fetchPublicUsers()
    fetchInvitations()
     fetchFriendsRequests() 
    setInterval(() => {
      fetchFriendsRequests() 
    }, 3000); // Rafraîchir
  }, []); // Ce useEffect est déclenché une fois au montage du composant

  const rejectFriend = (friendId) => {
    userApi.removeFriend(friendId)
    setFriendAction(!friendAction)
  }

  const acceptFriend = async (friendId) => {
    try {
      // Appel API pour accepter l'ami
      await userApi.acceptFriendship(friendId);

      // Trouver l'ami dans les demandes d'ami
      const friendIndex = friendRequests.findIndex(friend => friend.id === friendId);
      if (friendIndex > -1) {
        const newFriend = friendRequests[friendIndex];
        console.log('Ajout du nouvel ami '+newFriend)

        // Mettre à jour la liste des demandes d'ami
        const updatedRequests = [...friendRequests];
        updatedRequests.splice(friendIndex, 1);
        setFriendRequests(updatedRequests);

        // Ajouter cet ami à la liste des amis
        setFriends([...friends, newFriend]);
      }

      // Mise à jour pour refléter le changement dans l'interface utilisateur
      setFriendAction(!friendAction);
    } catch (error) {
      console.error('Erreur lors de l\'acceptation de la demande d\'ami', error);
    }
  };
  
  const cancelInvitation = async (friendId) => {
    try {
      // Appel API pour accepter l'ami
      await userApi.removeFriend(friendId);

      // Trouver l'ami dans les demandes d'ami
      const friendIndex = invitations.findIndex(friend => friend.id === friendId);
      if (friendIndex > -1) {
        const newFriend = invitations[friendIndex];
        console.log('Suppression invitation ami '+newFriend)

        // Mettre à jour la liste des demandes d'ami
        const updatedRequests = [...invitations];
        updatedRequests.splice(friendIndex, 1);
        setInvitations(updatedRequests);
      }
    } catch (error) {
      console.error('Erreur lors de l\'acceptation de la demande d\'ami', error);
    }
  };

  const addFriend = async (friendId) => {
    try {
      // Appel API pour accepter l'ami
      await userApi.addFriend(friendId);
  
      // Trouver l'ami dans les demandes d'ami
      const friendIndex = publicUsers.findIndex(friend => friend.id === friendId);
      if (friendIndex > -1) {
        const newFriend = publicUsers[friendIndex];
        console.log('demande de nouvel ami '+newFriend)
  
        // Mettre à jour la liste des demandes d'ami
        const updatedRequests = [...publicUsers];
        updatedRequests.splice(friendIndex, 1);
        setPublicUsers(updatedRequests);
  
        // Ajouter cet ami à la liste des amis
        setInvitations([...invitations, newFriend]);
      }
 
    } catch (error) {
      console.error('Erreur lors de l\'envoi de la demande d\'ami', error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    searchUsers(searchTerm)
  }
  
  const searchUsers = async (s) => {
    try { 
      const searchUsers = await userApi.searchUsers(s);
      
        setPublicUsers(searchUsers); 

    } catch (error) {
      console.error('Erreur lors de la recherche', error);
    }
  };

// Filtre des users (plus utile, on filtre directement dans api mais code à garder)
//   const currentUserID =  JSON.parse(localStorage.getItem('user')).id
//  const friendIDs = friends.map(friend => friend.id);
//  const filteredPublicUsers = publicUsers.filter(publicUser => {
//    console.log(`Vérification de l'utilisateur ${publicUser.id}: Est-il un ami?`, friendIDs.includes(publicUser.id));
//    return !friendIDs.includes(publicUser.id) && publicUser.id !== currentUserID;
//  });
//  console.log("Utilisateurs Filtrés (Filtered Public Users):", filteredPublicUsers);

  return (
    <div className="flex flex-col md:flex-row mt-8">
      <div className="md:w-80 w-full mb-5 mt-5">  
        <div className="px-4"> 
          <div className="titlefriend">
            <img src={amis} alt="amis"/>
            <h1> Tous les amis </h1>
          </div>
          <div className="allFriends">
            {friendRequests.length > 0 && <h2>Invitations reçues</h2>}
            {
              friendRequests.map((f, index) => 
                <div className="friend friend-request" key={index}>
                  <img src={f.profile_picture || process.env.PUBLIC_URL + "/profile_pic_default_2.svg" || "https://i.imgur.com/A7lNstm.jpg"} alt="profile" />
                  <div className="infodivfriend">
                    <p className="notranslate"> {f.firstname} {f.lastname} </p>
                    <span className="infofriendspan notranslate">{helpers.truncateString(f.email, 20)}</span>
                  </div>
                  <img className="friend_heart"  onClick={() => { acceptFriend(f.id) }} src={heart} alt="Accepter" title="Accepter" />
                </div>
              )
            }
          </div>
          <div className="allFriends">
            {invitations.length > 0 && <h2>Invitations envoyées</h2>}
            {
              invitations.map((f, index) => 
                <div className="friend friend-sent" key={index}>
                  <img src={f.profile_picture || process.env.PUBLIC_URL + "/profile_pic_default_2.svg" || "https://i.imgur.com/A7lNstm.jpg"} alt="profile" />
                  <div className="infodivfriend">
                    <p className="notranslate"> {f.firstname} {f.lastname} </p>
                    <span className="infofriendspan notranslate">{helpers.truncateString(f.email, 20)}</span>
                    <img className="imgCancel" onClick={() => { cancelInvitation(f.id) }} src={cancel} alt="Annuler" title="Anuler la demande" />
                  </div> 
                </div>
              )
            }
          </div>
          <div className="allFriends"> 
            {friends.length > 0 && <h2>Amis confirmés</h2>}
            {
              friends.map((f, index) => 
                <div className="friend" key={index}>
                  <a href={`/friends/${f.id}`} className="flex"  >
                    <img src={f.profile_picture || process.env.PUBLIC_URL + "/profile_pic_default_2.svg" || "https://i.imgur.com/A7lNstm.jpg"} alt="profile"/>
                    <div className="infodivfriend">
                      <p className="notranslate"> {f.firstname} {f.lastname} </p>
                      <span className="infofriendspan notranslate">{helpers.truncateString(f.email, 20)}</span>
                    </div>
                  </a>
                  <img className="imgCancel" onClick={() => { rejectFriend(f.id) }} src={cancel} alt="Supprimer" title="supprimer" />
                </div>
              )
            }
          </div>
        </div> 
      </div> 
      <div className="flex-auto w-full mb-5"> 
        <div className="px-4"> 
          <div className="serach">
            <Paper
              component="form"
              onSubmit={handleSubmit}
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                maxWidth: "386px",
                marginTop: 0,
                borderRadius: "67px",
                border: "1px solid #008eb5"
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Recherche amis ..."
                inputProps={{ "aria-label": "Rechercher" }}
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
              <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>


          <h2 className="h2seg">Suggestions:</h2>
          <div className="w-full px-4">
            <div className="flex flex-wrap -mx-2">
              {publicUsers.map((f, index) => (
                <div 
                  key={index} 
                  className="w-full lg:w-1/2 xl:w-1/3 2xl:w-1/4 3xl:w-1/5 p-2"
                >
                  <div className="bg-[#e4e2e2] rounded-[29px] p-6 flex flex-col items-center">
                    <img 
                      src={f.profile_picture || "/profile_pic_default_2.svg"} 
                      alt="profile" 
                      className="w-[119px] h-[109px] rounded-full bg-blue-500 object-cover"
                    />
                    <div className="mt-2 text-center">
                      <p className="text-[15px] font-bold font-sans notranslate">
                        {helpers.truncateString(f.firstname, 10)} {helpers.truncateString(f.lastname, 10)}
                      </p>
                      <span className="text-[15px] font-bold text-[#009aad] notranslate">
                        {helpers.truncateString(f.email, 20)}
                      </span>
                    </div>
                    <button 
                      onClick={() => addFriend(f.id)}
                      className="mt-2 w-[109px] h-[34px] bg-[#009aad] text-white rounded-[47px] text-[19px] font-semibold font-sans"
                    >
                      Suivre
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Friend;
