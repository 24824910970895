import { useEffect, useState } from "react";
import * as helpers from "../../Helper/utils"; // Importez tout le module helper
import {ProfileHeader } from "./profileHeader"
import "./GoogleCSE.css"
import "./articles.css"
import externalScripts  from "../../Helper/useExternalScripts";
import { countries as allCountries } from "../../global/countriesAndSources"
import { addFavorite, removeFavorite } from "../../HTTP/articles"
import Map from "../Map/Map"
import translation from "../../HTTP/translation";
import { fetchCseFromThematic } from "../../HTTP/thematics";

const userCountry = JSON.parse(localStorage.getItem('user'))?.country
const favorites = JSON.parse(localStorage.getItem('favorites')); 
sessionStorage.setItem("currentlanguage",document.documentElement.lang === 'auto' ? navigator.languages[1] : document.documentElement.lang)

export default function GoogleCSE (props) {
    const [autoSearchLaunched, setAutoSearchLaunched] = useState(false)
    const country = props.selectedCountry

    const launchSearch = () => {
        if (window.launchSearchDone === true) return // to avoid recursion stack
        window.launchSearchDone = false
        console.log('lancement de la recherche')
        setTimeout(() => {
            const input = document.querySelector("input#gsc-i-id1")
            if (!input) {
                launchSearch() // recursion until element is found
                return
            }
            const query = sessionStorage.getItem("search_query")
            console.log("la recherche est lancée sur le terme " + query)
            if (query) {
                for (let i = 0; i < 5; i++){setTimeout(() => input.value = query, 500)}
            }
            const button = document.querySelector("button.gsc-search-button-v2")
            if (button){
                for (let i = 0; i < 5; i++){setTimeout(() => button.click(), 500)}
            }
            window.launchSearchDone = true
        }, 500)
    }

    const storageQuery = sessionStorage.getItem("search_query")
    if (storageQuery && !autoSearchLaunched) {
        setAutoSearchLaunched(true)
        launchSearch()
    }
    const q = new URLSearchParams(window.location.search).get('q')
            || storageQuery
            || ""

	const [selectedCountry] = useState(country)
	const [query, setQuery] = useState(q)


    const selectNewCountry = (country) => {
        window.location = `./?country=${country.code}&q=${query}`
        sessionStorage.setItem("search_country", JSON.stringify(country))
    }

	//const [clickedFavorites, setClickedFavorites] = useState([])

    /*function setNewQuery (query) {
        var url = new URL(window.location.href)
        url.searchParams.append('q',query)
        window
    }*/
    
    
        const [finalCSE, setFinalCSE] = useState(null);
        const [isLoading, setIsLoading] = useState(true);

        // Premier useEffect pour détecter le paramètre et faire l'appel API si nécessaire
        useEffect(() => {
            const setAppropriateCSE = async () => {
                // Récupération du paramètre thematic depuis l'URL
                const urlParams = new URLSearchParams(window.location.search);
                const thematic = urlParams.get('thematic');

                if (thematic && selectedCountry.code) {
                    try {
                        const result = await fetchCseFromThematic(thematic, selectedCountry.code);
                        
                        if (result) {
                            setFinalCSE(result); // On prend le premier CSE trouvé
                        } else {
                            setFinalCSE(selectedCountry.cse); // Fallback sur le CSE par défaut
                        }
                    } catch (error) {
                        console.error('Erreur lors de la récupération du CSE thématique:', error);
                        setFinalCSE(selectedCountry.cse); // Fallback sur le CSE par défaut
                    }
                } else {
                    setFinalCSE(selectedCountry.cse);
                }
                setIsLoading(false);
            };

            if (selectedCountry) {
                setIsLoading(true);
                setAppropriateCSE();
            }
        }, [selectedCountry]); 

        // Second useEffect pour le script Google CSE
        useEffect(() => {
            if (!finalCSE || isLoading) return;

            const script = document.createElement('script');
            script.src = `https://cse.google.com/cse.js?cx=${finalCSE}`;
            script.async = true;
            
            window.__gcse = {
                searchCallbacks: {
                    web: {
                        ready: function() {
                            const element = document.querySelector('.gsc-results-wrapper-overlay');
                            if (element) {
                                element.classList.remove('gsc-results-wrapper-overlay');
                                element.classList.add('gsc-results-wrapper-nooverlay');
                            }
                        }
                    }
                }
            };

            document.head.appendChild(script);

            return () => {
                if (document.head.contains(script)) {
                    document.head.removeChild(script);
                }
            };
        }, [finalCSE, isLoading]);

    /*
    useEffect(() => {
        const translateSearchField = () => {
            const searchBar = document.getElementById("gsc-i-id1");
            if (searchBar) {
                // Créer un élément temporaire pour la traduction
                const tempElement = document.createElement('span');
                tempElement.textContent = "Recherche...";
                tempElement.style.display = 'none';
                document.body.appendChild(tempElement);
    
                // Attendre que Google Translate traduise l'élément temporaire
                setTimeout(() => {
                    const translatedText = tempElement.textContent;
                    if (translatedText !== "Recherche...") {
                        searchBar.placeholder = translatedText;
                    }
                    document.body.removeChild(tempElement);
                }, 500);
    
                // Réinitialiser le style du champ de recherche
                searchBar.style.background = null;
            }
        };
    
        // Exécuter la fonction immédiatement et configurer un intervalle
        translateSearchField();
        const interval = setInterval(translateSearchField, 2000);
    
        // Nettoyage
        return () => clearInterval(interval);
    }, []);
    */
      
    function addToFav(event) {
        if (event.target.dataset.favorited){
            return
        }
        let contentDiv = event.target.parentElement.parentElement
        let titleAnchor = contentDiv.querySelector(".gsc-thumbnail-inside").querySelector(".gs-title").querySelector("a.gs-title")
        let link = titleAnchor.href
        let imageURL = contentDiv.querySelector("div.gsc-table-result").querySelector("div.gsc-table-cell-thumbnail")?.querySelector("div.gs-image-box")?.querySelector("a").querySelector("img").src
        imageURL = imageURL || ""
        let description = contentDiv.querySelector("div.gsc-table-result").querySelector("div.gsc-table-cell-snippet-close")?.querySelector(".gs-snippet")?.innerText || ""
        let formattedDate = new Date().toISOString().replace("T", " ").slice(0, 19)
        const favorite = {
            title: titleAnchor.textContent,
            link: link, 
            image: imageURL, //decodeURI(realURL) || decodeURIComponent(realURL)
            country: selectedCountry.name,
            publication_date: formattedDate, // Par défault, ou "2000-01-01 00:00:00"
            description: description
        }
        addFavorite(favorite).then((res) => { 
            if(res.ok === true){
                event.target.src=`${process.env.PUBLIC_URL}/bookmark.svg`
                favorite.id = res.id; 
                favorites.push(favorite); 
                localStorage.setItem('favorites', JSON.stringify(favorites));
                console.log(favorite)
                alert('Favorite article added !');
                event.target.onclick = removeFav;
            }
        })
    } 

    function removeFav(event) {
        if (event.target.dataset.favorited){
            return
        }
        let contentDiv = event.target.parentElement.parentElement
        let titleAnchor = contentDiv.querySelector(".gsc-thumbnail-inside").querySelector(".gs-title").querySelector("a.gs-title")
        let realURL = titleAnchor.href
        
        const favorite = { 
            link: realURL,  
        }
        removeFavorite(favorite).then((res) => { 
                event.target.src=`${process.env.PUBLIC_URL}/bookmarklight.svg` 
                event.target.onclick = addToFav;
                const updatedFavorites = helpers.removeLink(favorites, realURL); 
                localStorage.setItem('favorites', JSON.stringify(updatedFavorites)); 
        })
        alert('Favori article deleted !');
    } 

    function addBookMark(results, resultsContainer) { 
        results.forEach((result, i) => { 
            // if the wrapper already exists, return
            if (document.getElementById("wrapper_div_" + i)) return
            const div = result
            div.id = ("wrapper_div_" + i)
            div.classList.add("wrapper_for_bookmark")
            // const imageblock = div.querySelector(".gsc-table-cell-thumbnail");
            // console.log(imageblock.parentElement.parentElement.parentElement)
            // if (imageblock) {
                //     imageblock.parentElement.parentElement.parentElement.insertBefore(imageblock, imageblock.parentElement.parentElement.parentElement.firstChild);
                // }
            if(div.querySelector('.gsc-thumbnail-inside') == null ){ return }
            const link = helpers.getDomainName(div.querySelector("a.gs-title").href)
            div.querySelector('.gsc-thumbnail-inside').insertAdjacentHTML("beforeend",`<span class="text-cyan-600">Pays : ${selectedCountry.name} | Source : ${link}</span>`)
            // div.appendChild(result)
            // resultsContainer.appendChild(div)
            const previous = document.getElementById("bookmark_"+i)
            if (!previous) { 
                // Check if already favorited
                let titleAnchor = div.querySelector("a.gs-title")
                let realURL = titleAnchor.href
                const present = helpers.checkLink(favorites, realURL);
                if(present === true) {
                    div.insertAdjacentHTML("beforeend", 
                    `<div class="bookmark" id=${"bookmark_"+i}>
                    <img
                        class="removeFavButton"
                        src="${process.env.PUBLIC_URL}/bookmark.svg"
                        alt="bookmark vector image"
                        height="40"
                        width="55"
                        title="Retirer le favori" />
                    </div>`
                    )
                }
                else {
                    div.insertAdjacentHTML("beforeend", 
                    `<div class="bookmark" id=${"bookmark_"+i}>
                    <img
                        class="favButton"
                        src="${process.env.PUBLIC_URL}/bookmarklight.svg"
                        alt="bookmark vector image"
                        height="40"
                        width="55" 
                        title="Ajouter en favori"
                        />
                    </div>`
                    )
                }
            }
            document.querySelectorAll(".favButton").forEach((e) => e.onclick = addToFav)
            document.querySelectorAll(".removeFavButton").forEach((e) => e.onclick =removeFav)
        })
    }

    // Handle search result changes on the fly
    useEffect(() => {

        // input change for searchbar
        window.addEventListener("change", (e) => {
            if (e.target.id === "gsc-i-id1") { 
                if (e.target.value === undefined) return
                if (e.target.value.length > 0) { 
                    setQuery(e.target.value); 
                    sessionStorage.setItem("search_query", e.target.value) 
                }
            }
        })

        window.previousPage = 0
        window.intervalCount = 0
        const replaceUrl = "http://translate.zemus.fr/translate.php"
        function setIntervalCustom (func, time) {
            window.intervalCount ++
            return setInterval(func, time)
        }
        // Reloads changeTitles until it finds the dom elements
        window.interval = setIntervalCustom(changeTitles, 150)

        // restart interval when click on pagination, search, or select lang button
        window.addEventListener("click", (e) => {
            if (e.target.classList.contains("gsc-cursor-page")) {
                const previous = document.querySelector(".gsc-cursor-current-page")
                if (previous) window.previousPage = previous.innerText
                window.interval = setIntervalCustom(changeTitles, 100)
            }
            if (e.target.classList.contains("gsc-cursor-container-next") || e.target.classList.contains("gsc-cursor-container-previous") || e.target.classList.contains("gsc-cursor-chevron")) {
                const previous = document.querySelector(".gsc-cursor-numbered-page")
                window.previousPage = previous ? previous.innerText.match(/(\d+)$/)[1] : '1'
                window.interval = setIntervalCustom(changeTitles, 1000)
            }
            if (e.target.classList.contains("gsc-search-button") || e.target.classList.contains("gs-spelling")) { 
                clearInterval(window.interval)
                window.interval = setIntervalCustom(changeTitles, 100)
            }
            if (e.target.classList.contains("gsc-search-button")) {
                setQuery(document.getElementById("gsc-i-id1").value)
            }
        })

        function deleteStuff() {
            let adBlock = document.querySelector(".gsc-adBlock")
            if (adBlock) adBlock.style.display = "none"

            let refinementBlock = [].filter.call( document.querySelectorAll('.gsc-positioningWrapper') || [], (e) => e.querySelector('.gsc-refinementBlock') != null )[0]
            if (refinementBlock) refinementBlock.style.display = "none"
        }

        async function changeLink() {
            let titles = document.querySelectorAll("a.gs-title")
            let results = document.querySelectorAll("div.gsc-result")
            const selected_country_code = selectedCountry.code || "fr"
            const selected_lang_code = sessionStorage.getItem('currentlanguage')
            const selected_source_lang = await translation.getLangFromCountry(selected_country_code)

            
            if (titles.length > 0  && results.length > 0) {
                titles.forEach(e => {
                    if (e.href.includes(replaceUrl)) {
                        console.log("already replaced for link", e.innerText)
                        e.setAttribute("href", e.href.substring(0, e.href.length-2) + selected_lang_code)
                        return
                    }
                    e.dataset.hostname = e.href.split("/")[2]
                    e.dataset.cturl = "#"
                    e.target = "_blank"
                    e.setAttribute("href", replaceUrl + "?site=" + e.href + "&article=" + e.href + "&lgsrc=" + selected_source_lang + "&lgdst=" + selected_lang_code);
                })
            }

        }

        function changeTitles() {
            if (window.intervalCount < 0) return
            let titles = document.querySelectorAll("a.gs-title")
            let descriptions = document.querySelectorAll("div.gs-bidi-start-align.gs-snippet")
            let adTitles = document.getElementsByClassName("p_")
            let results = document.querySelectorAll("div.gsc-result")
            const resultsContainer = document.querySelector(".gsc-expansionArea")
            titles = [...titles, ...adTitles]

            deleteStuff()

            
            // Si les résultats sont affichés
            if (titles.length > 0  && results.length > 0) {
                const current = document.querySelector(".gsc-cursor-current-page")?.innerText
                if (current === window.previousPage) { 
                    return
                } else if (current === undefined) {
                    const current2 = document.querySelector(".gsc-cursor-numbered-page") ? document.querySelector(".gsc-cursor-numbered-page")?.innerText.match(/(\d+)$/)[1] : '1'
                    if (current2 === window.previousPage) {
                        return
                    }
                }

                clearInterval(window.interval)

                // trouble here clearing all the intervals. changeTitle keeps running
                for (let i = 0; i <= window.intervalCount; i++){
                    window.intervalCount --
                    window.clearInterval(window.interval)
                }  
                // console.log("window.interval ", window.interval)
                if (JSON.parse(localStorage.getItem("subscriptions")) !== null) {
                    addBookMark(results, resultsContainer)
                }
                //console.log("traduction des titres")
                titles.forEach(async e => {
                    const selected_country_code = selectedCountry.code || "fr"
                    const selected_lang_code = sessionStorage.getItem('currentlanguage')
                    const selected_source_lang = await translation.getLangFromCountry(selected_country_code)
                    //console.log("selected_country_code", selected_country_code)
                    //console.log("selected_lang_code", selected_lang_code)

                    
                    const titleText = encodeURIComponent(e.innerText)
                    if (titleText.length > 1) {
                        translation.getTranslation(selected_source_lang, selected_lang_code, titleText)
                        .then(text => e.innerText = text)
                        .catch("nope")
                    }

                    // Si le href a déjà été modifié, return
                    if (e.href.includes(replaceUrl)) {
                        return
                    }

                    e.dataset.hostname = e.href.split("/")[2]
                    e.dataset.cturl = "#"
                    e.target = "_blank"

                    //console.log("href AVANT", e.href)
                    e.setAttribute("href", replaceUrl + "?site=" + e.href + "&article=" + e.href + "&lgsrc=" + selected_source_lang + "&lgdst=" + selected_lang_code);
                    //console.log("e.href APRES", e.href)
                })

                //console.log("traduction des descriptions")
                descriptions.forEach(async e => {
                    const selected_country_code = selectedCountry.code || "fr"
                    const selected_lang_code = sessionStorage.getItem('currentlanguage')
                    const selected_source_lang = await translation.getLangFromCountry(selected_country_code)
                    const descriptionText = encodeURIComponent(e.innerText)
                    // console.log(descriptionText)
                    // console.log(e.innerText)
                    if (descriptionText.length > 1) {
                        translation.getTranslation(selected_source_lang, selected_lang_code, descriptionText)
                        .then(text => e.innerText = text)
                        .catch("nope")
                    }
                })
            }
	    }

        // Fonction pour gérer les mutations
        const handleMutations = (mutations) => {
                mutations.forEach(async mutation => { 
                    if (mutation.target.matches('div#wrapper_div_9.gsc-webResult.gsc-result.wrapper_for_bookmark')) {
                        console.log("detected")
                        clearInterval(window.interval);
                        window.interval = setIntervalCustom(changeTitles, 100);
                    }
                    const currentLanguage = sessionStorage.getItem('currentlanguage')
                    const newLanguage = document.documentElement.lang
                    const input = document.querySelector('input#gsc-i-id1')
                    const query = sessionStorage.getItem('search_query')
                    if (currentLanguage !== newLanguage) {
                        sessionStorage.setItem('currentlanguage',newLanguage === 'auto' ? navigator.languages[1] : newLanguage);
                        changeLink()
                        //console.log("changement de langue")
                        // 1. recuperer l'imput du champ de recherche
                        /*const input = document.querySelector('input#gsc-i-id1')
                        // 2. traduire le champ de recherche 
                        const trad = translation.getTranslation(currentLanguage,newLanguage,input.value)
                        .then(text => {
                            console.log('new translation : ' + text)
                            // 3. remplacer l'imput du champ de recherche par la trad
                            sessionStorage.setItem("search_query",text)
                            //document.getElementById('globeDiv').style.display = 'none'
                            // 4. relancer la recherche
                            window.launchSearch = false
                            console.log("la recherche est lancée sur le terme " + text)
                            if (text) {
                                for (let i = 0; i < 5; i++){setTimeout(() => input.value = text, 500)}
                            }
                            const button = document.querySelector("button.gsc-search-button-v2")
                            if (button){
                                for (let i = 0; i < 5; i++){setTimeout(() => button.click(), 500)}
                            }
                            window.launchSearchDone = true
                            changeTitles()
                        })
                        .catch((err) => console.log("nope"))*/
                    }
                    const sessionCodeCountry = JSON.parse(sessionStorage.getItem("search_country"))
                    if (input) {
                        if (document.getElementById("translate_query").checked) {
                            const langDest = await translation.getLangFromCountry(sessionCodeCountry.code)
                            const translated = await translation.getTranslation(sessionStorage.getItem("currentlanguage"), langDest, input.value)
                            // console.log(input.value)
                            // console.log(query)
                            // console.log(translated)
                            if (query !== translated && input.value !== translated) {
                                sessionStorage.setItem("search_query", translated);
                                if (translated) {
                                    for (let i = 0; i < 5; i++){setTimeout(() => input.value = translated, 500)}
                                }
                                window.launchSearchDone = false
                                const button = document.querySelector("button.gsc-search-button-v2")
                                if (button){
                                    for (let i = 0; i < 5; i++){setTimeout(() => button.click(), 500)}
                                }
                                window.launchSearchDone = true
                                changeTitles()
                            }
                            document.getElementById("translate_query").checked = false
                        }
                    }
                    let results = document.querySelectorAll(".gsc-webResult .gsc-result")
                    const resultsContainer = document.querySelector(".gsc-expansionArea")
                    if (JSON.parse(localStorage.getItem("subscriptions")) !== null) {
                        addBookMark(results, resultsContainer)
                    }
                });
        };

        // Sélectionnez l'élément à observer
        const targetNode = document.querySelector('#root');
        
        if (targetNode) {
            
            // Options de l'observateur (quels changements observer)
            const config = { childList: true, subtree: true };

            // Créez une instance de l'observateur
            const observer = new MutationObserver(handleMutations);

            // Commencez à observer le nœud cible pour les mutations configurées
            observer.observe(targetNode, config);

            // Nettoyez l'observateur lors du démontage
            return () => {
                observer.disconnect();
            };
        }
    }, []);
    
    return (
        <>
            <div className="gcse-searchresults" />
        </>
    )
}

