import ElementMobile from "./ElementMobile";

export default function ListElementsMobile({columns, data, buttons, little}) {
    console.log('les boutons', buttons)
    return (
        <div className={little ? "h-[calc(100vh-24rem)] lg:h-[calc(100vh-12rem)] overflow-y-auto" : "h-[calc(100vh-8rem)] overflow-y-auto"}>
            {data.map((item, index) => (
                <ElementMobile 
                    key={item.article?.id || `item-${index}`}
                    column={columns} 
                    element={item} 
                    buttons={buttons}
                />
            ))}
        </div>
    );
}