import React, { useEffect, useState } from 'react';
import { getArticlesByAuthorId, removeOwnArticle } from '../../HTTP/articles';
import { encodeHTML, formatDate, formatDateExtended, htmlDecode, removeHtmlTags } from '../../Helper/utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '../../Helper/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import PeopleIcon from '@mui/icons-material/People';
import { Add } from '@mui/icons-material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { countries } from '../../global/countriesAndSources';

export default function MyArticles() {
    if (localStorage.getItem("subscriptions") === null) {
        window.location.href = '/'
    } else {
      if (JSON.parse(localStorage.getItem("subscriptions")).data === null) {
        window.location.href = '/'
      }
    }
    const url = new URL(window.location.href)
      if (url.searchParams.get('page') === null) {
          url.searchParams.set('page', 1);
          window.history.pushState({}, '', url);
      }
      if (url.searchParams.get('sortby') === null) {
        url.searchParams.set('sortby', 'title');
        window.history.pushState({}, '', url);
    } if (url.searchParams.get('filter') === null) {
      url.searchParams.set('filter', -1);
      window.history.pushState({}, '', url);
  }

  const [articles, setArticles] = useState({count:0, rows:[]});
  const [searchTerm, setSearchTerm] = useState(url.searchParams.get('query') === null ? '' : url.searchParams.get('query'));
  const [currentPage, setCurrentPage] = useState(parseInt(url.searchParams.get('page')));
  const [sortBy, setSortBy] = useState(url.searchParams.get('sortby') === null ? 'title' : url.searchParams.get('sortby'))
  const [itemsPerPage] = useState(15);
  const [filter, setFilter] = useState(url.searchParams.get('filter') === null ? -1 : url.searchParams.get('filter'))
  const user = JSON.parse(localStorage.getItem("user"))

  
  useEffect(() => {
    const funArticles = async () => {
      const resArticles = await getArticlesByAuthorId(user.id, (currentPage - 1) * itemsPerPage, currentPage * itemsPerPage, searchTerm, sortBy, filter)
      setArticles(resArticles)
    }
    funArticles()
  }, [])
  
  const handleSelectedValueOnList = (e) => {
    window.location.href = `/myarticles?page=1&filter=${e.target.value}${searchTerm === '' ? '' : '&query='+searchTerm}`
  }

  const handleSearch = (e) => {
    e.preventDefault();
    if (url.searchParams.get('query') === null) {
        url.searchParams.set('query', searchTerm);
        window.history.pushState({}, '', url);
    }
    window.location.href = "/myarticles?page=1&query=" + searchTerm
  };

  const handleAdd = () => {
    window.location.href = "/myarticles/create"
  };

  const handleEdit = (id) => {
    window.location.href = "/myarticles/edit/" + id
  };

  const handleDelete = (item) => {
        removeOwnArticle(item).then(async () => {
          alert("Removed successfully !")
        }).catch((err) => alert("An error occured while removing the article"))
        window.location.href = "/myarticles"
  };

  const handleBackToDashboard = () => {
    // Logique de retour au tableau de bord à implémenter
  };

  const handleViewArticle = (id) => {
    window.location.href = `https://static.zemus.info/article/?id=${id}`
  }

  // Changer de page
  const paginate = (pageNumber) => window.location.href = "/myarticles?page=" + pageNumber + (url.searchParams.get("sortby") === null ? '' : "&sortby=" + url.searchParams.get("sortby")) + (url.searchParams.get("query") === null ? '' : "&query=" + url.searchParams.get("query"));

  const formatVisible = (visible) => {
    switch (visible) {
      case 0: return "Brouillon"
      case 1: return "En ligne"
      case 2: return "En ligne (en cours de vérification)"
      case 3: return "En ligne (vérifié)"
      default: return "-"
    }
  }

  return (
    <div className="w-full px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between mt-10 mb:mt-0 mb-4">
        <h1 className="text-2xl font-bold text-center flex-grow">
          Mes articles
        </h1>
      </div>
      <form onSubmit={handleSearch} className="w-full mb-4">
        <div className="flex flex-col space-y-3 lg:flex-row lg:space-y-0 lg:space-x-3">
          {/* Container principal qui prend toute la largeur disponible */}
          <div className="flex-1 flex space-x-2">
            <input
              type="text"
              placeholder="Rechercher..."
              className="flex-1 px-4 py-2.5 rounded-lg border border-gray-200 shadow-sm transition-colors focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-20 focus:outline-none"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              type="submit"
              className="px-4 py-2.5 bg-blue-500 hover:bg-blue-600 text-white rounded-lg shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              <SearchIcon />
            </button>
            <button
              type="button"
              className="px-4 py-2.5 bg-green-500 hover:bg-green-600 text-white rounded-lg shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              onClick={handleAdd}
            >
              <AddIcon />
            </button>
          </div>

          {/* Select avec une largeur fixe */}
          <select 
            value={filter} 
            onChange={handleSelectedValueOnList}
            className="w-full lg:w-48 px-4 py-2.5 rounded-lg border border-gray-200 shadow-sm bg-white transition-colors focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-20 focus:outline-none appearance-none"
            name="visibility"
          >
            <option value={-1}>Tous les articles</option>
            <option value={0}>Brouillon</option>
            <option value={1}>En attente de vérification</option>
            <option value={2}>En cours de vérification</option>
            <option value={3}>En ligne</option>
          </select>
        </div>
      </form>

      <div className="md:block w-full shadow-md rounded-lg h-[calc(100vh-18rem)] overflow-y-auto">
        <table className="w-full table-auto">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"><a href={`/myarticles?page=1&sortby=title${searchTerm === '' ? '' : '&query='+searchTerm}`}>Titre{sortBy === 'title' && <ArrowDropUpIcon />}</a></th>
              <th className="hidden lg:table-cell px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"><a href={`/myarticles?page=1&sortby=created_at`}>Date de création{sortBy === 'created_at' && <ArrowDropUpIcon />}</a></th>
              <th className="hidden xl:table-cell px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"><a href={`/myarticles?page=1&sortby=updated_at`}>Date d'actualisation{sortBy === 'updated_at' && <ArrowDropUpIcon />}</a></th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"><a href={`/myarticles?page=1&sortby=visible`}>Statut{sortBy === 'visible' && <ArrowDropUpIcon />}</a></th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className=" divide-y divide-gray-200">
            {articles.rows.map((item, index) => (
              <tr key={item.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-4 py-4 break-words">
                  <div className="max-w-xs lg:max-w-sm xl:max-w-md">{item.title}</div>
                </td>
                <td className="hidden lg:table-cell px-4 py-4">{formatDateExtended(item.created_at)}</td>
                <td className="hidden xl:table-cell px-4 py-4">{formatDateExtended(item.updated_at)}</td>
                <td className="px-4 py-4">{formatVisible(item.visible)}</td>
                <td className="px-4 py-4 whitespace-nowrap overflow-x-auto">
                <button className="bg-green-500 hover:bg-green-700 text-white font-bold rounded mr-1 p-1" onClick={() => handleViewArticle(item.id)}>
                <VisibilityIcon />
                </button>
                  <button 
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded mr-1 p-1"
                    onClick={() => handleEdit(item.id)}
                  >
                    <EditIcon />
                  </button>
                  {
                    item.visible === 3 && <button 
                    className="bg-purple-500 hover:bg-purple-700 text-white font-bold rounded mr-1 p-1"
                    title='Ajouter à une revue de presse'
                    onClick={() => {
                      const data = [{
                        country: countries.find((c) => c.code === user.country).name,
                        description: encodeHTML(item.description).slice(0,150),
                        image: '',
                        link: `https://static.zemus.info/article/?id=${item.id}`,
                        title: item.title
                      }]
                      window.location.href=`/addtoreview?data=${encodeURIComponent(JSON.stringify(data))}`
                    }}
                  >
                    <ArrowOutwardIcon />
                  </button>
                  }
                  {
                    item.visible === 3 && <button 
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded mr-1 p-1"
                    title="Ajouter à la revue de presse d'un de mes amis"
                    onClick={() => {
                      const data = [{
                        country: countries.find((c) => c.code === user.country).name,
                        description: encodeHTML(item.description).slice(0,150),
                        image: '',
                        link: `https://static.zemus.info/article/?id=${item.id}`,
                        title: item.title
                      }]
                      window.location.href=`/addtofriendreview?data=${encodeURIComponent(JSON.stringify(data))}`
                    }}
                  >
                    <PeopleIcon />
                    <ArrowOutwardIcon />
                  </button>
                  }
                  <button 
                    className="bg-red-500 hover:bg-red-700 text-white font-bold rounded mr-1 p-1"
                    onClick={() => {
                      if (window.confirm("Are you sure you want to delete this item ?")) {
                        handleDelete(item);
                      }
                    }}
                  >
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={articles.count}
        paginate={paginate}
        currentPage={currentPage}
      />
      
    </div>
  );
}